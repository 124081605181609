import { t } from "@lingui/macro"
import { observer } from "mobx-react"

import { NavigationItemDetailStore } from "src/modals/navigation-items-detail/store"

import { FormFieldSet } from "src/components/FormFieldSet"
import { SegmentPickerField } from "src/components/SegmentPickerField"
import { useStore } from "src/store/lib/useStore"

export const PublishingSettingsSection = observer(() => {
    const store = useStore(NavigationItemDetailStore)
    return (
        <FormFieldSet
            header={t`navigation-items-view.publishing-section.used-for`}
        >
            <SegmentPickerField
                value={store.segmentIDs}
                accessGroupId={store.accessGroupId}
                onChange={(segments) => store.setSegmentIDs(segments)}
                disabled={store.isReadOnly}
            />
        </FormFieldSet>
    )
})
