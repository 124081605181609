import { i18n as linguiI18n } from "@lingui/core"
import { en, sv } from "make-plural/plurals"

import { ILocale, Locale, Locales } from "src/locales/locale"
import { messages as enMessages } from "./en/messages"
import { messages as svMessages } from "./sv/messages"

export const i18n = linguiI18n

export const DEFAULT_LANGUAGE = Locale.Swedish

export function loadI18n() {
    // These language files could be lazy-loaded later when they grow too big.
    i18n.loadLocaleData(Locale.English, { plurals: en })
    i18n.load(Locale.English, enMessages)

    i18n.loadLocaleData(Locale.Swedish, { plurals: sv })
    i18n.load(Locale.Swedish, svMessages)
}

export function getLocale(): ILocale {
    const locale = Locales[i18n.locale as Locale]
    return locale ?? Locales[DEFAULT_LANGUAGE]
}
