import { t } from "@lingui/macro"
import { TextField } from "@mui/material"
import { observer } from "mobx-react"

import { FormFieldSet } from "src/components/FormFieldSet"
import { NoticeBoardPostDetailStore } from "src/modals/notice-board-post-detail/store"
import { useStore } from "src/store/lib/useStore"

export const SettingsSection = observer(() => {
    const store = useStore(NoticeBoardPostDetailStore)

    return (
        <FormFieldSet
            header={t`notice-board-post-detail-modal.settings-section.internal-name-header`}
            tooltip={t`notice-board-post-detail-modal.settings-section.internal-name-tooltip`}
        >
            <TextField
                label={t`notice-board-post-detail-modal.settings-section.internal-name-label`}
                placeholder={t`notice-board-post-detail-modal.settings-section.internal-name-placeholder`}
                onChange={store.fields.setter("admin_title")}
                value={store.fields.get("admin_title")}
                helperText={store.fields.error("admin_title")}
                error={Boolean(store.fields.error("admin_title"))}
                disabled={store.fields.get("access_type") === "READ"}
            />
        </FormFieldSet>
    )
})
