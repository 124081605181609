import { memo } from "react"

import { INavigationItemsPropsProps } from "src/modals/navigation-items-detail/types/navigationItemsProps"

import { ModalNumberField } from "src/components/ModalNumberField"

type NavigationItemKey = "generic" | keyof INavigationItemsPropsProps

export interface IRenderNumberInputProps {
    label: string
    key: NavigationItemKey
    disabled?: boolean
    maxLength?: number
    handleTextInputChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        key: string,
    ) => void
    value: number
    helperText?: string
}

export const NavigationItemNumberInput = memo(
    ({
        label,
        key,
        disabled,
        maxLength,
        handleTextInputChange,
        value,
        helperText,
    }: IRenderNumberInputProps) => {
        return (
            <ModalNumberField
                label={label ?? ""}
                value={value ?? 0}
                onChange={(event) => handleTextInputChange(event, key)}
                helperText={helperText}
                error={Boolean(helperText)}
                maxLength={maxLength ?? 255}
                disabled={disabled}
            />
        )
    },
)
