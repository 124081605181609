import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo } from "react"
import { useParams } from "react-router"
import { t } from "@lingui/macro"

import { ChatbotConversationStore } from "./store"

import { FadedRowCellContainer, RowCellContainer } from "./styles"

import { IChatbotConversationItem } from "./types/chatbot-conversation-item"

import { ChatbotStatusChip } from "./components/ChatbotChip"

import { ListPage } from "src/components/ListPage"
import { NoWrap } from "src/components/NoWrap"
import { IPageFilterProps } from "src/components/PageFilter"
import { SegmentPickerButton } from "src/components/SegmentPickerButton"
import { DataGridProTable } from "src/components/Table/DataGridPro"
import { Time } from "src/components/Time"
import { ChatbotConversationModalView } from "src/modals/chatbot"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { Repository } from "src/types/channel"
import { parseStatus } from "src/types/chatbot"
import { FilterModel, IColumn, SortModel } from "src/types/data-grid-pro"
import { TagsCell } from "src/components/TagsCell"
import { chatbotConversationsConstants } from "src/views/chatbot/constants"
import {
    getFilterModelForRepository,
    getSortModelForRepository,
} from "src/lib/data-grid-pro"

const repository: Repository = "chatbotConversation"

const View = observer(() => {
    const store = useStore(ChatbotConversationStore)
    const gstore = useStore(GlobalStore)
    const { conversationId } = useParams()

    const advanceQuery = useMemo(() => {
        const filter = getFilterModelForRepository(
            repository,
            gstore.session.dataGridFilterModel,
        )
        const sort = getSortModelForRepository(
            repository,
            gstore.session.dataGridSortModel,
        )
        return { sort, filter }
    }, [gstore.session.dataGridSortModel, gstore.session.dataGridFilterModel])

    useEffect(() => {
        ;(async () => {
            await store.init(gstore.session.accessGroupId, advanceQuery)
        })()

        return () => {
            store.dispose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store, gstore.session.accessGroupId, gstore.session.segmentIds])

    useEffect(() => {
        ;(async () => {
            if (conversationId !== undefined) {
                await openDetailModalHandler(conversationId)
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleFilterChange = useCallback(
        async (model: FilterModel) => {
            const sort = getSortModelForRepository(
                repository,
                gstore.session.dataGridSortModel,
            )

            await store.query({ sort, filter: model })
        },
        [store, gstore.session.dataGridSortModel],
    )

    const handleSortChange = useCallback(
        async (model: SortModel) => {
            const filter = getFilterModelForRepository(
                repository,
                gstore.session.dataGridFilterModel,
            )

            await store.query({ filter, sort: model })
        },
        [store, gstore.session.dataGridFilterModel],
    )

    const openDetailModalHandler = useCallback(
        (conversationId?: string) => {
            if (conversationId !== undefined) {
                gstore.modals.open(() => (
                    <ChatbotConversationModalView
                        conversationId={conversationId}
                    />
                ))
            }
        },
        [gstore.modals],
    )

    const handleSegmentChange = useCallback(
        (segments: number[]) => store.loadSegments(segments),
        [store],
    )

    const header = useMemo(
        () => ({
            header: t`chatbot-view.header`,
            breadcrumbs: [t`navigation.chatbot`, t`chatbot-view.header`],
        }),
        [],
    )

    const filter = useMemo(
        (): IPageFilterProps => ({
            actions: (
                <>
                    <SegmentPickerButton
                        value={store.segments}
                        onChange={handleSegmentChange}
                    />
                </>
            ),
        }),
        [handleSegmentChange, store.segments],
    )

    const tagsValueOptions = useMemo(() => {
        return (store.tags ?? []).map((tag) => ({
            label: tag.name,
            value: tag.tag_id,
        }))
    }, [store.tags])

    const columns: IColumn<IChatbotConversationItem>[] = [
        {
            field: "conversation_id",
            headerName: t`chatbot-view.list.conversation-id`,
            description: t`chatbot-view.list.conversation-id-description`,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "string",
            sortable: false,
        },
        {
            field: "created_at",
            headerName: t`chatbot-view.list.start-date`,
            description: t`chatbot-view.list.start-date-description`,
            minWidth: 110,
            renderCell: (params) => <Time date={params.value} />,
            type: "dateTime",
        },
        {
            field: "tenant_id",
            headerName: t`chatbot-view.list.tenant-id`,
            description: t`chatbot-view.list.tenant-id-description`,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "number",
            sortable: false,
        },
        {
            field: "tags",
            headerName: t`chatbot-view.list.tags`,
            description: t`chatbot-view.list.tags-description`,
            renderCell: (params) => <TagsCell value={params.value} />,
            type: "singleSelect",
            // @ts-ignore
            // Value options is available on one of the options for type of TColumn(GridSingleSelectColDef), but it's not reading here
            valueOptions: tagsValueOptions,
            sortable: false,
        },
        {
            field: "tenant_feedback_thumb_status",
            headerName: t`chatbot-view.list.end-user-feedback`,
            description: t`chatbot-view.list.end-user-feedback-description`,
            renderCell: (params) =>
                params.value !== -1 ? (
                    <NoWrap>
                        <ChatbotStatusChip
                            status={
                                params.value === 1
                                    ? parseStatus("positive")
                                    : parseStatus("negative")
                            }
                        />
                    </NoWrap>
                ) : (
                    <FadedRowCellContainer>
                        {parseStatus("unknown")}
                    </FadedRowCellContainer>
                ),
            type: "singleSelect",
            // @ts-ignore
            // Value options is available on one of the options for type of TColumn(GridSingleSelectColDef), but it's not reading here
            valueOptions:
                chatbotConversationsConstants.feedbackThumbStatusOptions,
            sortable: false,
        },
        {
            field: "tenant_feedback_reason",
            headerName: t`chatbot-view.list.tenant-feedback-reason`,
            description: t`chatbot-view.list.tenant-feedback-reason-description`,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            sortable: false,
            filterable: false,
            type: "singleSelect",
            // @ts-ignore
            // Value options is available on one of the options for type of TColumn(GridSingleSelectColDef), but it's not reading here
            valueOptions: chatbotConversationsConstants.tenantFeedbackReasons,
        },
        {
            field: "admin_feedback_thumb_status",
            headerName: t`chatbot-view.list.admin-feedback`,
            description: t`chatbot-view.list.admin-feedback-description`,
            renderCell: (params) =>
                params.value !== -1 ? (
                    <NoWrap>
                        <ChatbotStatusChip
                            status={
                                params.value === 1
                                    ? parseStatus("positive")
                                    : parseStatus("negative")
                            }
                        />
                    </NoWrap>
                ) : (
                    <FadedRowCellContainer>
                        {parseStatus("unknown")}
                    </FadedRowCellContainer>
                ),
            type: "singleSelect",
            // @ts-ignore
            // Value options is available on one of the options for type of TColumn(GridSingleSelectColDef), but it's not reading here
            valueOptions:
                chatbotConversationsConstants.feedbackThumbStatusOptions,
            sortable: false,
        },
        {
            field: "admin_feedback_reason",
            headerName: t`chatbot-view.list.admin-feedback-reason`,
            description: t`chatbot-view.list.admin-feedback-reason-description`,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            sortable: false,
            type: "singleSelect",
            // @ts-ignore
            // Value options is available on one of the options for type of TColumn(GridSingleSelectColDef), but it's not reading here
            valueOptions: chatbotConversationsConstants.adminFeedbackReasons,
        },
    ]
    return (
        <ListPage
            header={header}
            filter={filter}
            loading={gstore.loading.is(
                ChatbotConversationStore.LoadingKeys.init,
            )}
        >
            <DataGridProTable
                paginator={store.chatbotList}
                data={store.chatbotList.items}
                columns={columns}
                advancedOperations={{
                    pagination: "server",
                    sorting: "server",
                    filtering: "server",
                }}
                onRowClickEvent={(item) =>
                    openDetailModalHandler(item.row.conversation_id)
                }
                repository={repository}
                onFilterChange={handleFilterChange}
                onSortChange={handleSortChange}
                loading={gstore.loading.is(
                    ChatbotConversationStore.LoadingKeys.loading,
                )}
            />
        </ListPage>
    )
})

export const ChatbotConversation = observer(() => (
    <StoreProvider Store={ChatbotConversationStore}>
        <View />
    </StoreProvider>
))
