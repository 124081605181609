import { t } from "@lingui/macro"
import {
    alpha,
    Stack,
    styled,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useTheme,
} from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo } from "react"

import { SegmentPickerStore } from "src/modals/segment-picker/store"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

const ToggleChipGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    flexWrap: "wrap",
    marginBottom: "-8px",
    "& .MuiToggleButtonGroup-grouped": {
        margin: "0 8px 8px",
        background: theme.palette.grey[300],
        border: "none",
        color: theme.palette.grey[900],
        padding: "0 12px",
        height: "32px",
        textTransform: "none",
        fontSize: "12px",
        "&:disabled:not(.Mui-selected)": {
            border: "none",
            background: alpha(theme.palette.common.black, 0.08),
            opacity: 0.38,
        },
        "&.Mui-selected, &.Mui-selected:hover": {
            background: theme.palette.grey[900],
            color: theme.palette.common.white,
        },
        "&:not(:first-of-type), &:first-of-type": {
            borderRadius: "1000px",
        },
        "&:first-of-type": {
            marginLeft: 0,
        },
        "&:last-of-type": {
            marginRight: 0,
        },
        "& svg": {
            marginRight: "4px",
        },
    },
}))

export const SegmentType = observer(() => {
    const store = useStore(SegmentPickerStore)
    const globalStore = useStore(GlobalStore)
    const theme = useTheme()

    const handleChange = useCallback(
        async (_: unknown, value: ISegmentForSearch["type"] | null) => {
            if (value !== null) {
                store.setSegmentType(value)
                await store.availableSegments.loadInitialPage()
            }
        },
        [store],
    )

    useEffect(() => {
        if (globalStore.session.selectedParakeyType === "Private") {
            store.setSegmentType("propertyobject")
        }
    }, [globalStore.session.selectedParakeyType, store])

    const translations = useMemo(
        (): { [key in ISegmentForSearch["type"]]: string } => ({
            legalentity: t`segment-picker.legalentity-segment-type`,
            property: t`segment-picker.property-segment-type`,
            propertyowner: t`segment-picker.property-owner-segment-type`,
            building: t`segment-picker.building-segment-type`,
            address: t`segment-picker.address-segment-type`,
            propertyobject: t`segment-picker.object-segment-type`,
            district: t`segment-picker.district-segment-type`,
            area1: t`segment-picker.area1-segment-type`,
            area2: t`segment-picker.area2-segment-type`,
            area3: t`segment-picker.area3-segment-type`,
            other: t`segment-picker.other-segment-type`,
        }),
        [],
    )
    return (
        <Stack spacing={0.5}>
            <Typography
                fontSize={14}
                fontWeight={400}
                color={theme.palette.grey[900]}
            >
                {t`segment-picker.available.segment-type`}
            </Typography>
            <div>
                <ToggleChipGroup
                    value={store.segmentType}
                    onChange={handleChange}
                    exclusive
                >
                    {globalStore.session.selectedParakeyType === "Private"
                        ? store.segmentTypes
                              .filter((type) => type === "propertyobject")
                              .map((item) => (
                                  <ToggleButton
                                      key={item}
                                      value={item}
                                      disabled={store.typeIsLowerThanOrEqualToParent(
                                          item,
                                      )}
                                  >
                                      {translations[item]}
                                  </ToggleButton>
                              ))
                        : store.segmentTypes.map((type) => (
                              <ToggleButton
                                  key={type}
                                  value={type}
                                  disabled={store.typeIsLowerThanOrEqualToParent(
                                      type,
                                  )}
                              >
                                  {translations[type]}
                              </ToggleButton>
                          ))}
                </ToggleChipGroup>
            </div>
        </Stack>
    )
})
