import { Tooltip, Chip, useTheme, SxProps } from "@mui/material"

import { TooltipContainer } from "src/styledComponents/TooltipContainer"
import { ILocationChipCommonProps } from "src/views/content-items/types"

export const LocationChipsCommon = (props: ILocationChipCommonProps) => {
    const { palette } = useTheme()

    const chipStyle: SxProps = {
        backgroundColor: palette.utility.darkBlue.main,
        color: palette.common.white,
    }
    return (
        <TooltipContainer>
            <Tooltip title={props.title} key={props.title.toString()}>
                <Chip label={props.label ?? props.title} sx={chipStyle} />
            </Tooltip>
        </TooltipContainer>
    )
}
