import { t } from "@lingui/macro"
import { observer } from "mobx-react"

import { ChatbotConfiguringStore } from "src/modals/configuring-agents-detail/store"

import { FormFieldSet } from "src/components/FormFieldSet"
import { SegmentPickerField } from "src/components/SegmentPickerField"
import { useStore } from "src/store/lib/useStore"

export const PublishingSection = observer(() => {
    const store = useStore(ChatbotConfiguringStore)
    return (
        <FormFieldSet
            header={t`configuring-agents-detail.publishing-section.used-for`}
        >
            <SegmentPickerField
                value={store.form.get("segment_ids")}
                accessGroupId={store.form.get("access_group_id")}
                onChange={(segments) => store.form.set("segment_ids", segments)}
                disabled={store.isReadOnly}
            />
        </FormFieldSet>
    )
})
