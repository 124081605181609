import { IContentItemsLocationOption } from "src/views/content-items/types"

export const locationItemsOptionList: IContentItemsLocationOption = {
    all: "All",
    move_in_checklist: "Move in checklist",
    overview: "Overview",
    navigation: "Navigation",
    spotlight: "Spotlight",
    wizard: "Wizard",
}
