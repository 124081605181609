/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { admin_CopyFeatureFlagsRequest } from "../models/admin_CopyFeatureFlagsRequest"
import type { admin_GetFeatureConfigResponse } from "../models/admin_GetFeatureConfigResponse"
import type { admin_GetFeatureFlagsRequest } from "../models/admin_GetFeatureFlagsRequest"
import type { admin_GetFeatureFlagsResponse } from "../models/admin_GetFeatureFlagsResponse"
import type { admin_UpdateFeatureConfigRequest } from "../models/admin_UpdateFeatureConfigRequest"
import type { admin_UpdateFeatureFlagsRequest } from "../models/admin_UpdateFeatureFlagsRequest"
import type { config_ConfigurableFeaturesResponse } from "../models/config_ConfigurableFeaturesResponse"
import type { config_CreateFeatureConfigRequest } from "../models/config_CreateFeatureConfigRequest"
import type { config_FeatureConfig } from "../models/config_FeatureConfig"
import type { config_GetFeatureConfigListRequest } from "../models/config_GetFeatureConfigListRequest"
import type { config_GetFeatureConfigListResponse } from "../models/config_GetFeatureConfigListResponse"
import type { config_GetFeatureConfigResponse } from "../models/config_GetFeatureConfigResponse"
import type { config_PublishConfigRequest } from "../models/config_PublishConfigRequest"
import type { config_PublishConfigResponse } from "../models/config_PublishConfigResponse"
import type { config_UpdateFeatureConfigRequest } from "../models/config_UpdateFeatureConfigRequest"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class FeaturesAdminService {
    /**
     * Get feature config
     * Get feature config for a legal entity
     * @returns admin_GetFeatureConfigResponse OK
     * @throws ApiError
     */
    public static getV1AdminFeaturesConfig({
        legalEntityId,
    }: {
        /** Legal entity id **/
        legalEntityId: number
    }): CancelablePromise<admin_GetFeatureConfigResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/features/config",
            query: {
                legal_entity_id: legalEntityId,
            },
        })
    }

    /**
     * Update feature config
     * Update feature config for legal entities
     * @returns any OK
     * @throws ApiError
     */
    public static putV1AdminFeaturesConfig({
        request,
    }: {
        /** Request body to update feature config **/
        request: admin_UpdateFeatureConfigRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/features/config",
            body: request,
        })
    }

    /**
     * Update feature flags
     * Update feature flags for legal entities
     * @returns any OK
     * @throws ApiError
     */
    public static putV1AdminFeaturesFlag({
        request,
    }: {
        /** Request body to update feature flags **/
        request: admin_UpdateFeatureFlagsRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/features/flag",
            body: request,
        })
    }

    /**
     * Copy feature flags
     * Copy feature flags from a legal entity to other legal entities
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminFeaturesFlagCopy({
        request,
    }: {
        /** Request body to copy feature flags **/
        request: admin_CopyFeatureFlagsRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/features/flag/copy",
            body: request,
        })
    }

    /**
     * Has feature flags
     * Returns ids of legal entities who have feature flags
     * @returns number OK
     * @throws ApiError
     */
    public static getV1AdminFeaturesFlagHasFlags({
        propertyOwnerId,
    }: {
        /** Property owner id **/
        propertyOwnerId: number
    }): CancelablePromise<Array<number>> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/features/flag/has-flags",
            query: {
                property_owner_id: propertyOwnerId,
            },
        })
    }

    /**
     * Get feature flags
     * Get enabled feature flags for legal entities
     * @returns admin_GetFeatureFlagsResponse OK
     * @throws ApiError
     */
    public static postV1AdminFeaturesFlagList({
        request,
    }: {
        /** Request body to get feature flags **/
        request: admin_GetFeatureFlagsRequest
    }): CancelablePromise<admin_GetFeatureFlagsResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/features/flag/list",
            body: request,
        })
    }

    /**
     * Create feature config
     * Creates a new feature configuration
     * @returns config_FeatureConfig OK
     * @throws ApiError
     */
    public static postV2AdminFeatureConfig({
        request,
    }: {
        /** config.CreateFeatureConfigRequest **/
        request: config_CreateFeatureConfigRequest
    }): CancelablePromise<config_FeatureConfig> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v2/admin/feature/config",
            body: request,
        })
    }

    /**
     * Get configurable features
     * Gets all features that can be configured by current admin
     * @returns config_ConfigurableFeaturesResponse OK
     * @throws ApiError
     */
    public static getV2AdminFeatureConfigConfigurableFeatures(): CancelablePromise<config_ConfigurableFeaturesResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v2/admin/feature/config/configurable-features",
        })
    }

    /**
     * Get feature configs
     * Gets all feature configurations
     * @returns config_GetFeatureConfigListRequest OK
     * @throws ApiError
     */
    public static postV2AdminFeatureConfigList({
        request,
    }: {
        /** config.GetFeatureConfigListResponse **/
        request: config_GetFeatureConfigListResponse
    }): CancelablePromise<config_GetFeatureConfigListRequest> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v2/admin/feature/config/list",
            body: request,
        })
    }

    /**
     * Get feature config
     * Gets a feature configuration by ID
     * @returns config_GetFeatureConfigResponse OK
     * @throws ApiError
     */
    public static getV2AdminFeatureConfig({
        configId,
    }: {
        /** Config ID **/
        configId: string
    }): CancelablePromise<config_GetFeatureConfigResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v2/admin/feature/config/{config_id}",
            path: {
                config_id: configId,
            },
        })
    }

    /**
     * Update feature config
     * Updates an existing feature configuration
     * @returns config_GetFeatureConfigResponse OK
     * @throws ApiError
     */
    public static putV2AdminFeatureConfig({
        configId,
        request,
    }: {
        /** Config ID **/
        configId: string
        /** config.UpdateFeatureConfigRequest **/
        request: config_UpdateFeatureConfigRequest
    }): CancelablePromise<config_GetFeatureConfigResponse> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v2/admin/feature/config/{config_id}",
            path: {
                config_id: configId,
            },
            body: request,
        })
    }

    /**
     * Delete feature config
     * Deletes a feature configuration
     * @returns void
     * @throws ApiError
     */
    public static deleteV2AdminFeatureConfig({
        configId,
    }: {
        /** Config ID **/
        configId: string
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v2/admin/feature/config/{config_id}",
            path: {
                config_id: configId,
            },
        })
    }

    /**
     * Publish feature config
     * Publishes a feature configuration
     * @returns config_PublishConfigResponse OK
     * @throws ApiError
     */
    public static putV2AdminFeatureConfigPublish({
        configId,
        request,
    }: {
        /** Config ID **/
        configId: string
        /** config.PublishConfigRequest **/
        request: config_PublishConfigRequest
    }): CancelablePromise<config_PublishConfigResponse> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v2/admin/feature/config/{config_id}/publish",
            path: {
                config_id: configId,
            },
            body: request,
        })
    }
}
