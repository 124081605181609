import { t } from "@lingui/macro"
import {
    Box,
    Button,
    Chip as MUIChip,
    FormHelperText as MUIFormHelperText,
    Stack,
    styled,
} from "@mui/material"
import { observer } from "mobx-react"
import React, { useCallback, useEffect } from "react"

import { PreselectedSegmentsLoader } from "./PreselectedSegmentsLoader"
import { SegmentPickerFieldStore } from "./store"

import { SegmentChip } from "src/components/SegmentPickerField/SegmentChip"
import { SegmentPickerModal } from "src/modals/segment-picker"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { SessionStore } from "src/store/session"

const Field = styled(Box, { shouldForwardProp: (name) => name !== "error" })<{
    error?: boolean
}>(({ theme, error }) => ({
    borderWidth: "1px",
    borderColor:
        error === true ? theme.palette.error.main : theme.palette.grey[500],
    borderStyle: "solid",
    padding: "8px 8px 0 8px",
    borderRadius: "4px",
    minHeight: "50px",
    boxSizing: "border-box",
    width: "100%",
}))

const Chip = styled(MUIChip)({
    border: "none",
    background: "transparent",
    fontSize: "13px",
    margin: "0 8px 8px 0",
})

const EmptyState = styled("div")(({ theme }) => ({
    display: "block",
    padding: "0 4px",
    marginBottom: "8px",
    lineHeight: 2,
    color: theme.palette.grey[700],
    cursor: "default",
}))

const FormHelperText = styled(MUIFormHelperText)({
    margin: "3px 14px 0",
})

interface IProps {
    value: number[]
    onChange: (segmentIds: number[]) => void
    accessGroupId?: number | null
    error?: boolean
    helperText?: React.ReactNode
    disabled?: boolean
    skipLoadingSegments?: boolean
}

const MAX_VISIBLE_SEGMENT_CHIPS = 8

const SegmentPickerFieldImpl = observer((props: IProps) => {
    const gstore = useStore(GlobalStore)
    const store = useStore(SegmentPickerFieldStore)

    useEffect(() => {
        ;(async () => {
            await store.init(
                props.accessGroupId ?? null,
                props.skipLoadingSegments,
            )
        })()
    }, [store, props.accessGroupId, props.skipLoadingSegments])

    const visibleSelectedSegments = store.segments
        .filter((segment) => props.value.includes(segment.id))
        .slice(0, MAX_VISIBLE_SEGMENT_CHIPS)

    const handleSegmentPickerOpenClick = useCallback(() => {
        gstore.modals.open(
            () => (
                <SegmentPickerModal
                    initialSegmentIds={props.value}
                    accessGroupId={props.accessGroupId ?? null}
                    onChange={(segmentIds) => {
                        props.onChange(segmentIds)
                        gstore.modals.pop()
                    }}
                    onClose={() => {
                        gstore.modals.pop()
                    }}
                />
            ),
            { variant: "slide-up-w995" },
        )
    }, [gstore.modals, props])

    const reloadingAccessGroup = gstore.loading.is(
        SessionStore.LoadingKeys["reload-access-group-segments"],
    )

    const loadingSegments = gstore.loading.is(
        SegmentPickerFieldStore.LoadingKeys.init,
    )

    const disabled =
        reloadingAccessGroup || loadingSegments || props?.disabled === true

    const loadingPreselectedSegments = loadingSegments && props.value.length > 0

    return (
        <div>
            <Field error={props.error}>
                {loadingPreselectedSegments ? (
                    <PreselectedSegmentsLoader />
                ) : (
                    <>
                        {visibleSelectedSegments.length === 0 && (
                            <EmptyState>{t`segment-picker-field.empty-state`}</EmptyState>
                        )}
                        {visibleSelectedSegments.map((segment) => (
                            <SegmentChip key={segment.id} segment={segment} />
                        ))}
                        {props.value.length > MAX_VISIBLE_SEGMENT_CHIPS && (
                            <Chip
                                variant="outlined"
                                label={t({
                                    id: "segment-picker-field.additional-segments",
                                    values: {
                                        count:
                                            props.value.length -
                                            visibleSelectedSegments.length,
                                    },
                                })}
                                disabled={disabled}
                                onClick={handleSegmentPickerOpenClick}
                            />
                        )}
                    </>
                )}
            </Field>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                {props.helperText != null ? (
                    <FormHelperText error={props.error}>
                        {props.helperText}
                    </FormHelperText>
                ) : (
                    <span />
                )}
                <Button
                    onClick={handleSegmentPickerOpenClick}
                    disabled={disabled}
                    color="info"
                    sx={{ marginTop: "16px" }}
                >
                    {t`segment-picker-field.add-or-remove`}
                </Button>
            </Stack>
        </div>
    )
})

export const SegmentPickerField = observer((props: IProps) => (
    <StoreProvider Store={SegmentPickerFieldStore}>
        <SegmentPickerFieldImpl {...props} />
    </StoreProvider>
))
