import { Box, Stack, styled } from "@mui/material"
import { observer } from "mobx-react"

import { SegmentPickerSearch } from "src/modals/segment-picker/SegmentPickerSearch"
import { SegmentPickerSelected } from "src/modals/segment-picker/SegmentPickerSelected"

const Divider = styled("hr")(({ theme }) => ({
    background: theme.palette.grey[500],
    minWidth: "1px",
    width: "1px",
    margin: "40px 0 40px -1px",
    border: "none",
}))

const ModalColumn = styled(Box)({
    flex: "0 0 50%",
    width: "50%",
    padding: "32px 32px 24px 32px",
    boxSizing: "border-box",
})

interface IProps {
    onSave: () => void
    onClose: () => void
    initialSegmentIds: number[]
}

export const SplitSegmentPicker = observer((props: IProps) => {
    return (
        <Stack direction="row" divider={<Divider />} sx={{ height: "100%" }}>
            <ModalColumn>
                <SegmentPickerSearch />
            </ModalColumn>
            <ModalColumn>
                <SegmentPickerSelected
                    onSave={props.onSave}
                    onClose={props.onClose}
                    initialSegmentIds={props.initialSegmentIds}
                />
            </ModalColumn>
        </Stack>
    )
})
