import { memo } from "react"

export interface ILinkGuidelineProps {
    link: string
    target?: string
    text: string
}

export const LinkGuideline = memo(
    ({ link = "#", target = "_blank", text = "" }: ILinkGuidelineProps) => {
        return (
            <a href={link} target={target} rel="noreferrer">
                {text}
            </a>
        )
    },
)
