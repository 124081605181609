export const currencyValues = {
    "- None -": "",
    SEK: "SEK",
    EUR: "EUR",
}

export const languageValues = {
    "- None -": "",
    "sv, en": "sv, en",
    "fi, en, sv": "fi, en, sv",
}
