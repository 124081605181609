import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { Typography, Box } from "@mui/material"
import { useCallback } from "react"

import { ChatbotConfiguringStore } from "src/modals/configuring-agents-detail/store"
import { persistFiles } from "src/lib/file"
import { FormFields } from "src/modals/configuring-agents-detail/components/formField"
import ImageSelectionSection from "src/components/AssetAttachmentsField"
import { useStore } from "src/store/lib/useStore"

export const ChatbotConfigurationSection = observer(() => {
    const store = useStore(ChatbotConfiguringStore)

    const handleIconChange = useCallback(
        async (images: IFile[]) => {
            const icon = images[0]
            if (Object.keys(icon).length > 0) {
                const images = await persistFiles([icon as ILocalFile], "image")

                if (images.length > 0) {
                    store.form.set("avatar_url", images[0].url)
                }
            }
        },
        [store.form],
    )
    return (
        <Box>
            <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle2" gutterBottom>
                    {t`configuring-agents-detail.avatar`}
                </Typography>
                <Typography variant="body2" gutterBottom>
                    {t`configuring-agents-detail.upload-image-be-used`}
                </Typography>
                <ImageSelectionSection
                    imageUrl={store.form.get("avatar_url")}
                    onImageDelete={() => store.form.set("avatar_url", "")}
                    onImageChange={(images) => handleIconChange(images)}
                    errorMessage={store.form.error("avatar_url") ?? ""}
                    setErrorMessage={(error) =>
                        store.form.setError("avatar_url", error)
                    }
                    acceptedFormats={["image/png", "image/jpeg"]}
                    dimensions={{ width: 150, height: 150 }}
                    disabled={store.isReadOnly}
                />
            </Box>
            <Box>
                <Typography variant="subtitle2" gutterBottom>
                    {t`configuring-agents-detail.content`}
                </Typography>

                <FormFields.ConfigAgentTextInput
                    label={t`configuring-agents-detail.headline-swedish`}
                    tooltipText={t`configuring-agents-detail.tooltip.headline-swedish`}
                    key={"header_headline_sv"}
                    rowLength={1}
                    disabled={store.isReadOnly}
                    maxLength={255}
                    value={store.form.get("header_headline_sv")}
                    helperText={store.form.error("header_headline_sv") ?? ""}
                    handleTextInputChange={(event) => {
                        store.form.set("header_headline_sv", event.target.value)
                    }}
                    error={Boolean(store.form.error("header_headline_sv"))}
                />

                <FormFields.ConfigAgentTextInput
                    label={t`configuring-agents-detail.headline-english`}
                    tooltipText={t`configuring-agents-detail.tooltip.headline-english`}
                    key={"header_headline_en"}
                    value={store.form.get("header_headline_en")}
                    rowLength={1}
                    disabled={store.isReadOnly}
                    maxLength={255}
                    helperText={store.form.error("header_headline_en") ?? ""}
                    handleTextInputChange={(event) => {
                        store.form.set("header_headline_en", event.target.value)
                    }}
                    error={Boolean(store.form.error("header_headline_en"))}
                />

                <FormFields.ConfigAgentTextInput
                    label={t`configuring-agents-detail.headline-finnish`}
                    tooltipText={t`configuring-agents-detail.tooltip.headline-finnish`}
                    key={"header_headline_fi"}
                    value={store.form.get("header_headline_fi")}
                    rowLength={1}
                    disabled={store.isReadOnly}
                    maxLength={255}
                    helperText={store.form.error("header_headline_fi") ?? ""}
                    handleTextInputChange={(event) => {
                        store.form.set("header_headline_fi", event.target.value)
                    }}
                    error={Boolean(store.form.error("header_headline_fi"))}
                />

                <FormFields.ConfigAgentTextInput
                    label={t`configuring-agents-detail.description-swedish`}
                    tooltipText={t`configuring-agents-detail.tooltip.description-swedish`}
                    key={"header_description_sv"}
                    value={store.form.get("header_description_sv")}
                    rowLength={4}
                    disabled={store.isReadOnly}
                    maxLength={255}
                    helperText={store.form.error("header_description_sv") ?? ""}
                    handleTextInputChange={(event) => {
                        store.form.set(
                            "header_description_sv",
                            event.target.value,
                        )
                    }}
                    error={Boolean(store.form.error("header_description_sv"))}
                />
                <FormFields.ConfigAgentTextInput
                    label={t`configuring-agents-detail.description-english`}
                    tooltipText={t`configuring-agents-detail.tooltip.description-english`}
                    key={"header_description_en"}
                    value={store.form.get("header_description_en")}
                    rowLength={4}
                    disabled={store.isReadOnly}
                    maxLength={255}
                    helperText={store.form.error("header_description_en") ?? ""}
                    handleTextInputChange={(event) => {
                        store.form.set(
                            "header_description_en",
                            event.target.value,
                        )
                    }}
                    error={Boolean(store.form.error("header_description_en"))}
                />
                <FormFields.ConfigAgentTextInput
                    label={t`configuring-agents-detail.description-finnish`}
                    tooltipText={t`configuring-agents-detail.tooltip.description-finnish`}
                    key={"header_description_fi"}
                    value={store.form.get("header_description_fi")}
                    rowLength={4}
                    disabled={store.isReadOnly}
                    maxLength={255}
                    helperText={store.form.error("header_description_fi") ?? ""}
                    handleTextInputChange={(event) => {
                        store.form.set(
                            "header_description_fi",
                            event.target.value,
                        )
                    }}
                    error={Boolean(store.form.error("header_description_fi"))}
                />
                <FormFields.ConfigAgentTextInput
                    label={t`configuring-agents-detail.intro-message-swedish`}
                    tooltipText={t`configuring-agents-detail.tooltip.intro-message-swedish`}
                    key={"welcome_message_sv"}
                    value={store.form.get("welcome_message_sv")}
                    rowLength={4}
                    disabled={store.isReadOnly}
                    maxLength={255}
                    helperText={store.form.error("welcome_message_sv") ?? ""}
                    handleTextInputChange={(event) => {
                        store.form.set("welcome_message_sv", event.target.value)
                    }}
                    error={Boolean(store.form.error("welcome_message_sv"))}
                />

                <FormFields.ConfigAgentTextInput
                    label={t`configuring-agents-detail.intro-message-english`}
                    tooltipText={t`configuring-agents-detail.tooltip.intro-message-english`}
                    key={"welcome_message_en"}
                    value={store.form.get("welcome_message_en")}
                    rowLength={4}
                    disabled={store.isReadOnly}
                    maxLength={255}
                    helperText={store.form.error("welcome_message_en") ?? ""}
                    handleTextInputChange={(event) => {
                        store.form.set("welcome_message_en", event.target.value)
                    }}
                    error={Boolean(store.form.error("welcome_message_en"))}
                />
                <FormFields.ConfigAgentTextInput
                    label={t`configuring-agents-detail.intro-message-finnish`}
                    tooltipText={t`configuring-agents-detail.tooltip.intro-message-finnish`}
                    key={"welcome_message_fi"}
                    value={store.form.get("welcome_message_fi")}
                    rowLength={4}
                    disabled={store.isReadOnly}
                    maxLength={255}
                    helperText={store.form.error("welcome_message_fi") ?? ""}
                    handleTextInputChange={(event) => {
                        store.form.set("welcome_message_fi", event.target.value)
                    }}
                    error={Boolean(store.form.error("welcome_message_fi"))}
                />
            </Box>
        </Box>
    )
})
