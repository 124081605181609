import { t } from "@lingui/macro"
import { observer } from "mobx-react"

import { Box } from "@mui/material"

import { ChatbotConfiguringStore } from "src/modals/configuring-agents-detail/store"

import { useStore } from "src/store/lib/useStore"
import { FormFields } from "src/modals/configuring-agents-detail/components/formField"

export const MetaDataSection = observer(() => {
    const store = useStore(ChatbotConfiguringStore)
    return (
        <Box>
            <FormFields.ConfigAgentTextInput
                variant="default"
                label={t`configuring-agents-detail.internal-name`}
                key={"name"}
                value={store.form.get("name")}
                rowLength={1}
                disabled={store.isReadOnly}
                maxLength={255}
                helperText={
                    store.form.error("name") ??
                    t`configuring-agents-detail.description-helper`
                }
                error={Boolean(store.form.error("name"))}
                handleTextInputChange={(event) => {
                    store.form.set("name", event.target.value)
                }}
            />
            <FormFields.ConfigAgentDropdown
                optionArray={store.configurableAgentsLists}
                selectedValue={store.form.get("agent_name")}
                onSelectionChange={(value) =>
                    store.form.set("agent_name", `${value}`)
                }
                errorMessage={store.form.error("agent_name") ?? ""}
                disabled={store.isReadOnly}
                label={t`configuring-agents-detail.agent-type`}
            />
        </Box>
    )
})
