import { styled } from "@mui/material"
import { observer } from "mobx-react"
import React, { useCallback } from "react"

import { HighlightedQuery } from "src/components/HighlightedQuery"
import { Tooltip } from "src/components/Tooltips/Tooltip"
import { SegmentPickerStore } from "src/modals/segment-picker/store"
import { useStore } from "src/store/lib/useStore"

const BreadcrumbDefaultButton = styled("button")(({ theme }) => ({
    fontSize: "12px",
    lineHeight: 1.4,
    color: theme.palette.grey[700],
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "inline",
    border: "none",
    background: "transparent",
    padding: 0,
    flexShrink: 0,
    "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
    },
    "&:active": {
        opacity: 0.8,
    },
    "&:last-of-type": {
        flexShrink: 1,
    },
}))

const BreadcrumbTooltipButton = styled("button")(({ theme }) => ({
    fontSize: "10px",
    lineHeight: 1.4,
    color: theme.palette.grey[900],
    fontWeight: 500,
    wordWrap: "break-word",
    wordBreak: "break-word",
    display: "inline",
    border: "none",
    background: "transparent",
    padding: 0,
    "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
    },
    "&:active": {
        opacity: 0.8,
    },
}))

const DefaultBreadcrumbWrapper = styled("span")({
    fontSize: "12px",
    lineHeight: 1.4,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    display: "inline-flex",
    cursor: "default",
})

interface IProps {
    segment: ISegmentForSearch
}

type BreadcrumbButton =
    | typeof BreadcrumbDefaultButton
    | typeof BreadcrumbTooltipButton

type PathPair = { path: string; index: number }

const TRUNCATED_PATH_PLACEHOLDER = "…"

function truncatePathParts(parts: PathPair[]): PathPair[] {
    return parts.length > 2
        ? [
              parts[0],
              { path: TRUNCATED_PATH_PLACEHOLDER, index: -1 },
              parts[parts.length - 1],
          ]
        : parts
}

function toPathPairs(paths: string[]): PathPair[] {
    return paths.map((path, index) => ({ path, index }))
}

function joinBreadcrumbs(breadcrumbs: React.ReactNode[]) {
    return breadcrumbs.map((breadcrumb, i) => (
        <React.Fragment key={i}>
            {i > 0 && <span style={{ margin: "0 4px" }}>/</span>}
            {breadcrumb}
        </React.Fragment>
    ))
}

export const ListItemBreadcrumb = observer((props: IProps) => {
    const store = useStore(SegmentPickerStore)

    const createBreadcrumbClickHandler = useCallback(
        (id: number) => async () => {
            store.setParentId(id)
            await store.availableSegments.loadInitialPage()
        },
        [store],
    )

    const allPathParts = toPathPairs(props.segment.path.split(" / "))
    const truncatedPathParts = truncatePathParts(allPathParts)

    const buildBreadcrumbs = useCallback(
        (pathPairs: PathPair[], Component: BreadcrumbButton) => {
            return pathPairs.map((pair) => {
                if (pair.path === TRUNCATED_PATH_PLACEHOLDER) {
                    return (
                        <span key={TRUNCATED_PATH_PLACEHOLDER}>
                            {TRUNCATED_PATH_PLACEHOLDER}
                        </span>
                    )
                }

                const parent =
                    store.getAncestorAtIndex(props.segment.id, pair.index) ??
                    props.segment.id

                const parentSegment = store.getSegmentWithId(parent)
                if (
                    parentSegment != null &&
                    store.hasChildSegmentType(parentSegment)
                ) {
                    return (
                        <Component
                            key={parent}
                            onClick={createBreadcrumbClickHandler(parent)}
                        >
                            <HighlightedQuery
                                text={pair.path}
                                query={store.selectedSegments.meta.search}
                            />
                        </Component>
                    )
                }

                return (
                    <HighlightedQuery
                        key={parent}
                        text={pair.path}
                        query={store.selectedSegments.meta.search}
                    />
                )
            })
        },
        [props.segment, store, createBreadcrumbClickHandler],
    )

    const tooltipBreadcrumbs = joinBreadcrumbs(
        buildBreadcrumbs(allPathParts, BreadcrumbTooltipButton),
    )
    const breadcrumbs = joinBreadcrumbs(
        buildBreadcrumbs(truncatedPathParts, BreadcrumbDefaultButton),
    )

    return (
        <Tooltip
            disableInteractive={false}
            enterDelay={250}
            disableFocusListener
            title={<span>{tooltipBreadcrumbs}</span>}
            arrow
        >
            <DefaultBreadcrumbWrapper
                data-testid={`ListItemBreadcrumb/DefaultBreadcrumbWrapper-${props.segment.type}`}
            >
                {breadcrumbs}
            </DefaultBreadcrumbWrapper>
        </Tooltip>
    )
})
