import { t } from "@lingui/macro"
import { IconButton, Stack, styled } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { ArrowBackFilled24 } from "src/components/icons/ArrowBackFilled24"
import { Cross16 } from "src/components/icons/Cross16"
import { SegmentTypeIcon } from "src/modals/segment-picker/SegmentTypeIcon"
import { SegmentPickerStore } from "src/modals/segment-picker/store"
import { useStore } from "src/store/lib/useStore"

const Field = styled(Stack)(({ theme }) => ({
    background: theme.palette.grey[100],
    color: theme.palette.grey[900],
    borderRadius: 1000,
    padding: "0 1rem",
    height: "40px",
    flex: 1,
    fontWeight: 500,
    fontSize: "14px",
}))

export const Parent = observer(() => {
    const store = useStore(SegmentPickerStore)

    const handleHistoryBackClick = useCallback(async () => {
        store.setPreviousFilter()
        await store.availableSegments.loadInitialPage()
    }, [store])

    const handleClearHistoryClick = useCallback(async () => {
        store.resetFilter()
        await store.availableSegments.loadInitialPage()
    }, [store])

    if (store.parent == null) {
        return null
    }

    return (
        <Stack direction="row">
            <IconButton
                title={t`segment-picker.go-back-in-history`}
                onClick={handleHistoryBackClick}
            >
                <ArrowBackFilled24 />
            </IconButton>
            <Field
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
            >
                <Stack direction="row" spacing={1} alignItems="center">
                    <SegmentTypeIcon type={store.parent.type} size={24} />
                    <span>{store.parent?.name}</span>
                </Stack>
                <IconButton
                    title={t`segment-picker.clear-history`}
                    onClick={handleClearHistoryClick}
                >
                    <Cross16 />
                </IconButton>
            </Field>
        </Stack>
    )
})
