import { t } from "@lingui/macro"
import { Alert, Button, Stack, useTheme } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect } from "react"

import { MixpanelProperties } from "src/analytics/constants/properties"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { CenteredSpinner } from "src/components/CenteredSpinner"
import { Form } from "src/components/Form"
import { FormPanel } from "src/components/FormPanel"
import { Icon } from "src/components/icons"
import { Preview } from "src/components/icons/Preview"
import { ModalHeader } from "src/components/ModalHeader"
import { AvyPreview } from "src/components/Preview"
import { useInitializer } from "src/lib/initializer"
import { ContentSection } from "src/modals/notice-board-post-detail/ContentSection"
import { ImagesAndDocumentSection } from "src/modals/notice-board-post-detail/ImagesAndDocumentsSection"
import { PublishSettingsSection } from "src/modals/notice-board-post-detail/PublishSettingsSection"
import { SettingsSection } from "src/modals/notice-board-post-detail/SettingsSection"
import { NoticeBoardPostDetailStore } from "src/modals/notice-board-post-detail/store"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { useCloseConfirmationForForm } from "src/store/modals/use-close-confirmation-for-form"

interface IProps {
    id?: number
    mode?: string
}

const NoticeBoardPostDetail = observer((props: IProps) => {
    const gstore = useStore(GlobalStore)
    const store = useStore(NoticeBoardPostDetailStore)
    const theme = useTheme()

    useCloseConfirmationForForm(store.fields)

    const initialized = useInitializer(
        () => store.init(gstore.session.accessGroupId, props.id),
        [store, props.id, gstore.session.accessGroupId],
    )

    useEffect(() => {
        props.id !== null &&
            (props.id as number) > 0 &&
            trackModuleEvent("Notice Board | Detail View", {
                [MixpanelProperties.AccessGroupID]:
                    gstore.session.accessGroup?.id,
                [MixpanelProperties.AccessGroupName]:
                    gstore.session.accessGroup?.name,
                [MixpanelProperties.ItemID]: props.id,
            })
    }, [props.id, gstore.session])

    const handleSubmit = useCallback(async () => {
        await store.submit(props.mode ?? "")
        trackModuleEvent("Notice Board | Save", {
            [MixpanelProperties.AccessGroupID]: gstore.session.accessGroup?.id,
            [MixpanelProperties.AccessGroupName]:
                gstore.session.accessGroup?.name,
            [MixpanelProperties.UserID]: gstore.session.user?.adminId,
            [MixpanelProperties.PublishStartDate]:
                store.fields.get("publish_at")?.toISOString() ?? "",
            [MixpanelProperties.PublishEndDate]:
                store.fields.get("unpublish_at")?.toISOString() ?? "",
            [MixpanelProperties.PushNotification]: store.fields.data.send_push,
        })
        if (!store.fields.hasErrors()) {
            gstore.modals.popPreview()
            gstore.modals.pop()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store, gstore])

    if (
        !initialized ||
        gstore.loading.is(NoticeBoardPostDetailStore.LoadingKeys.init)
    ) {
        return <CenteredSpinner height="100vh" />
    }
    const isButtonDisabled =
        store.fields.get("access_type") === "READ" ||
        gstore.loading.is(NoticeBoardPostDetailStore.LoadingKeys.submit) ||
        store.fields.getIsDirty() === false

    const isPreviewButtonDisabled =
        gstore.loading.is(NoticeBoardPostDetailStore.LoadingKeys.preview) ||
        (!store.isEditMode && store.fields.getIsDirty() === false)

    const onPreviewClick = () => {
        gstore.modals.togglePreview(() => (
            <AvyPreview
                details={store.getDetailsPreviewUrl}
                list={store.getListPreviewUrl}
            />
        ))
    }

    return (
        <Form onSubmit={handleSubmit}>
            <ModalHeader>
                <Stack spacing={1} direction="row">
                    <Button
                        startIcon={
                            <Icon
                                icon={<Preview />}
                                size={20}
                                color={theme.palette.info.main}
                            />
                        }
                        disabled={isPreviewButtonDisabled}
                        variant="contained"
                        color="info"
                        onClick={onPreviewClick}
                        data-testid="preview-button"
                    >
                        {t`global.preview-button`}
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={isButtonDisabled}
                        data-testid="save-button"
                    >{t`notice-board-post-detail-modal.save-button`}</Button>
                </Stack>
            </ModalHeader>
            {Boolean(store.fields.error("generic")) && (
                <Alert severity="error">{store.fields.error("generic")}</Alert>
            )}
            <FormPanel
                sections={[
                    {
                        header: t`notice-board-post-detail-modal.settings-section.header`,
                        content: <SettingsSection />,
                    },
                ]}
            />
            <FormPanel
                sections={[
                    {
                        header: t`notice-board-post-detail-modal.publish-settings-section.header`,
                        content: <PublishSettingsSection mode={props.mode} />,
                    },
                ]}
            />
            <FormPanel
                sections={[
                    {
                        header: t`notice-board-post-detail-modal.content-section.header`,
                        content: <ContentSection />,
                    },
                    {
                        header: t`notice-board-post-detail-modal.image-and-documents-section.header`,
                        content: <ImagesAndDocumentSection />,
                    },
                ]}
            />
        </Form>
    )
})

export const NoticeBoardPostDetailModal = (props: IProps) => (
    <StoreProvider Store={NoticeBoardPostDetailStore}>
        <NoticeBoardPostDetail {...props} />
    </StoreProvider>
)
