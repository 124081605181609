import { useMemo } from "react"

import { t } from "@lingui/macro"

import { Stack, Tooltip, Typography } from "@mui/material"

import { IColumn } from "src/types/data-grid-pro"
import { LocationChips } from "src/views/content-items/components/LocationChips"
import { TooltipContainer } from "src/styledComponents/TooltipContainer"
import { AccessTypeHeader } from "src/components/AccessTypeHeader"
import { useStore } from "src/store/lib/useStore"
import { ContentItemsStore } from "src/views/content-items/store"
import { RowCellContainer } from "src/views/contact-forms/styled"
import { IContentItems, IContentTypes } from "src/views/content-items/types"

export const useContentItemColumns = (): IColumn<IContentItems>[] => {
    const store = useStore(ContentItemsStore)
    const contentTypes: IContentTypes[] = useMemo(() => {
        return [
            {
                label: t`navigation-items-view.columns.dropdown.module`,
                value: "module",
            },
            {
                label: t`navigation-items-view.columns.dropdown.embed`,
                value: "embed",
            },
        ]
    }, [])

    return useMemo(() => {
        return [
            {
                field: "id",
                headerName: t`navigation-items-view.columns.item-id`,
                renderCell: (params) => (
                    <RowCellContainer>{params.value}</RowCellContainer>
                ),
                type: "number",
                minWidth: 150,
                flex: 1,
            },
            {
                field: "internal_name",
                headerName: t`navigation-items-view.columns.internal-name`,
                renderCell: (params) => (
                    <RowCellContainer>{params.value}</RowCellContainer>
                ),
                type: "string",
                minWidth: 178,
                flex: 1,
            },
            {
                field: "entity",
                headerName: t`navigation-items-view.columns.type`,
                renderCell: (params) => (
                    <RowCellContainer>{params.value}</RowCellContainer>
                ),
                minWidth: 189,
                type: "singleSelect",
                valueOptions: contentTypes,
                flex: 1,
            },
            {
                field: "location",
                headerName: t`navigation-items-view.columns.location`,
                renderCell: (params) => (
                    <RowCellContainer>
                        <Stack direction="row" spacing={1}>
                            <LocationChips locations={params.value} />
                        </Stack>
                    </RowCellContainer>
                ),
                minWidth: 200,
                type: "string",
                filterable: false,
                sortable: false,
                flex: 1,
            },
            {
                field: store.priorityColumnName as keyof IContentItems,
                headerName:
                    t`navigation-items-view.columns.priority` +
                    ` (${store.locationFilterSelectedValue})`,
                renderCell: (params) => (
                    <RowCellContainer>
                        {Boolean(params.value) ? params.value : "-"}
                    </RowCellContainer>
                ),
                type: "string",
                minWidth: 200,
                filterable: false,
                flex: 1,
            },
            {
                field: "published_to",
                headerName: t`navigation-items-view.columns.published-to`,
                renderCell: (params) => {
                    const { value, row } = params
                    return (
                        <TooltipContainer sx={{ cursor: "pointer" }}>
                            <Tooltip
                                arrow
                                title={row?.segment_names?.join(", ") ?? ""}
                            >
                                <Typography>{value}</Typography>
                            </Tooltip>
                        </TooltipContainer>
                    )
                },
                filterable: false,
                sortable: false,
                type: "string",
                minWidth: 280,
            },
            {
                field: "access_type",
                headerName: t`navigation-items-view.columns.access-type`,
                renderCell: (params) => (
                    <AccessTypeHeader accessType={params.value} />
                ),
                type: "string",
                filterable: false,
                sortable: false,
                flex: 1,
                minWidth: 321,
            },
        ]
    }, [
        contentTypes,
        store.priorityColumnName,
        store.locationFilterSelectedValue,
    ])
}
