import { t } from "@lingui/macro"

import { variables, IAuth } from "./variables"

/**
 * Export all raw environment variables.
 */
export const environment = variables

export const DEFAULT_ACCESS_GROUP = {
    id: -1,
    // Lazily evaluate as `t` cannot be called a import time.
    get name() {
        return t`head.all-access-groups`
    },
}

export type { IAuth }
