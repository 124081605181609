import {
    Box,
    Stack,
    styled,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography,
} from "@mui/material"

import { Icon } from "src/components/icons"
import { formatInteger } from "src/lib/number"

type ChipVariant = "selected" | "ancestor-selected" | "regular"

const AncestorTooltip = styled<(props: TooltipProps) => JSX.Element>(
    ({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ),
)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        background: theme.palette.common.white,
        color: theme.palette.grey[900],
        fontSize: "10px",
        fontWeight: 500,
        filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1))",
        width: "180px",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
    },
}))

const ChipWrapper = styled(Box)<{
    variant: ChipVariant
}>(({ variant, theme }) => ({
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.05)",
    borderRadius: "1000px",
    padding: "2px 8px",
    cursor: "default",
    ...(variant === "selected"
        ? {
              background: theme.palette.success.main,
              color: theme.palette.common.white,
          }
        : {}),
    ...(variant === "ancestor-selected"
        ? {
              background: theme.palette.warning.main,
              color: theme.palette.common.white,
          }
        : {}),
    ...(variant === "regular"
        ? {
              background: theme.palette.common.white,
              color: theme.palette.grey[900],
          }
        : {}),
}))

interface IChipProps {
    variant: ChipVariant
    tenantCount: number
    icon: JSX.Element
    textColor: string
    tooltip?: string
}

export const Chip = (props: IChipProps) => {
    const chip = (
        <ChipWrapper variant={props.variant}>
            <Stack direction="row" spacing={0.5}>
                <Icon icon={props.icon} />
                <Typography fontSize="12px" color={props.textColor}>
                    {formatInteger(props.tenantCount)}
                </Typography>
            </Stack>
        </ChipWrapper>
    )
    if (props.tooltip != null) {
        return (
            <AncestorTooltip title={props.tooltip} placement="right" arrow>
                {chip}
            </AncestorTooltip>
        )
    } else {
        return chip
    }
}
