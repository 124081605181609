import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo } from "react"
import { Box, Tooltip, Typography } from "@mui/material"

import { useParams, useNavigate } from "react-router-dom"

import { RowCellContainer, ImageCellContainer } from "./styled"
import { NavigationItemsStore } from "./store"

import { DataGridProTable } from "src/components/Table/DataGridPro"
import { useStore } from "src/store/lib/useStore"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { Repository } from "src/types/channel"
import { GlobalStore } from "src/store"
import { IPageFilterProps } from "src/components/PageFilter"
import { SegmentPickerButton } from "src/components/SegmentPickerButton"
import { ListPage } from "src/components/ListPage"
import { FilterModel, IColumn, SortModel } from "src/types/data-grid-pro"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"
import { NavigationItemModalView } from "src/modals/navigation-items-detail"
import {
    INavigationItems,
    IContentTypes,
} from "src/views/navigation-items/types"
import {
    getFilterModelForRepository,
    getSortModelForRepository,
} from "src/lib/data-grid-pro"
import { ConfirmModal } from "src/modals/confirm"
import { TooltipContainer } from "src/styledComponents/TooltipContainer"
import { AccessTypeHeader } from "src/components/AccessTypeHeader"
import { useInitializer } from "src/lib/initializer"

const repository: Repository = "navigation-items"

const View = observer(() => {
    const store = useStore(NavigationItemsStore)
    const globalStore = useStore(GlobalStore)
    const { id } = useParams()

    const navigate = useNavigate()

    useInitializer(async () => {
        if (id !== undefined && !isNaN(Number(id))) {
            await globalStore.modals.open(() => (
                <NavigationItemModalView id={Number(id)} />
            ))
        }
    }, [id, globalStore])

    const contentTypes: IContentTypes[] = useMemo(() => {
        return [
            {
                label: t`navigation-items-view.columns.dropdown.module`,
                value: "module",
            },
            {
                label: t`navigation-items-view.columns.dropdown.embed`,
                value: "embed",
            },
        ]
    }, [])

    const columns: IColumn<INavigationItems>[] = [
        {
            field: "navigation_item_id",
            headerName: t`navigation-items-view.columns.navigation-id`,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "number",
            minWidth: 120,
            flex: 1,
        },
        {
            field: "icon",
            headerName: t`navigation-items-view.columns.icon`,
            renderCell: (params) => (
                <RowCellContainer>
                    <Box sx={{ width: 24, height: 24 }}>
                        <ImageCellContainer src={params.value} alt="icon" />
                    </Box>
                </RowCellContainer>
            ),
            type: "string",
            filterable: false,
            sortable: false,
            minWidth: 40,
            maxWidth: 90,
        },
        {
            field: "internal_name",
            headerName: t`navigation-items-view.columns.internal-name`,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "string",
            minWidth: 178,
            flex: 1,
        },
        {
            field: "entity",
            headerName: t`navigation-items-view.columns.type`,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            minWidth: 189,
            type: "singleSelect",
            // @ts-ignore
            // Value options is available on one of the options for type of TColumn(GridSingleSelectColDef), but it's not reading here
            valueOptions: contentTypes,
            flex: 1,
        },
        {
            field: "location",
            headerName: t`navigation-items-view.columns.location`,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            minWidth: 189,
            type: "string",
            filterable: false,
            sortable: false,
            flex: 1,
        },
        {
            field: "priority_main_navigation",
            headerName: t`navigation-items-view.columns.priority-navigation`,
            renderCell: (params) => (
                <RowCellContainer>
                    {Boolean(params.value) ? params.value : "-"}
                </RowCellContainer>
            ),
            type: "string",
            minWidth: 150,
            filterable: false,
            flex: 1,
        },
        {
            field: "priority_overview",
            headerName: t`navigation-items-view.columns.priority-overview`,
            renderCell: (params) => (
                <RowCellContainer>
                    {Boolean(params.value) ? params.value : "-"}
                </RowCellContainer>
            ),
            type: "string",
            filterable: false,
            minWidth: 160,
            flex: 1,
        },
        {
            field: "published_to",
            headerName: t`navigation-items-view.columns.published-to`,
            renderCell: (params) => {
                const { value, row } = params
                return (
                    <TooltipContainer sx={{ cursor: "pointer" }}>
                        <Tooltip
                            arrow
                            title={row?.segment_names?.join(", ") ?? ""}
                        >
                            <Typography>{value}</Typography>
                        </Tooltip>
                    </TooltipContainer>
                )
            },
            filterable: false,
            sortable: false,
            type: "string",
            minWidth: 150,
        },
        {
            field: "access_type",
            headerName: t`navigation-items-view.columns.access-type`,
            renderCell: (params) => (
                <AccessTypeHeader accessType={params.value} />
            ),
            type: "string",
            filterable: false,
            sortable: false,
            flex: 1,
        },
    ]

    const handleNewNavigationItemClick = useCallback(() => {
        globalStore.modals.open(() => <NavigationItemModalView />)
    }, [globalStore.modals])

    const header = useMemo(
        () => ({
            header: t`navigation-items-view.header.navigation-items`,
            breadcrumbs: [t`navigation-items-view.header.navigation-items`],
            createOptions: {
                onClick: handleNewNavigationItemClick,
                item: t`navigation-items-view.header.navigation-items`,
            },
        }),
        [handleNewNavigationItemClick],
    )
    const handleSegmentChange = useCallback(
        (segments: number[]) => store.loadSegments(segments),
        [store],
    )
    const filter = useMemo(
        (): IPageFilterProps => ({
            actions: (
                <>
                    <SegmentPickerButton
                        value={store.segments}
                        onChange={handleSegmentChange}
                    />
                </>
            ),
        }),
        [handleSegmentChange, store.segments],
    )

    useEffect(() => {
        ;(async () => await store.init(globalStore.session.accessGroupId))()

        return () => store.dispose()
    }, [store, globalStore.session.accessGroupId])

    const openDetailModalHandler = useCallback(
        (item) => {
            trackModuleEvent("Navigation Items | Detailed View", {
                [MixpanelProperties.ItemID]: item.id,
                [MixpanelProperties.AccessGroupName]:
                    globalStore.session.accessGroup?.name,
                [MixpanelProperties.AccessGroupID]:
                    globalStore.session.accessGroup?.id,
            })
            navigate(`/user-interface/navigation-items/${item.id}`, {
                state: { accessType: item?.row?.access_type },
            })
        },
        [
            globalStore.session.accessGroup?.id,
            globalStore.session.accessGroup?.name,
            navigate,
        ],
    )

    const handleFilterChange = useCallback(
        async (model: FilterModel) => {
            const sort = getSortModelForRepository(
                repository,
                globalStore.session.dataGridSortModel,
            )

            const modifiedModel: FilterModel = {
                ...model,
                items: model.items.map((item) => ({
                    ...item,
                    field: item.field === "tag_names" ? "tag_ids" : item.field,
                })),
            }

            await store.query({ sort, filter: modifiedModel })
        },
        [store, globalStore.session.dataGridSortModel],
    )

    const handleSortChange = useCallback(
        async (model: SortModel) => {
            const filter = getFilterModelForRepository(
                repository,
                globalStore.session.dataGridFilterModel,
            )

            await store.query({ filter, sort: model })
        },
        [store, globalStore.session.dataGridFilterModel],
    )

    const handleDeleteItem = useCallback(
        (id: number) => {
            globalStore.modals.open(
                () => (
                    <ConfirmModal
                        onConfirm={async (confirmed) => {
                            if (confirmed) {
                                await store.deleteItem(id)
                            }
                        }}
                        title={t`navigation-items-view.delete.are-you-sure`}
                        content={t`navigation-items-view.delete.do-you-want-delete`}
                    />
                ),
                {
                    variant: "slide-up-w600",
                },
            )
        },
        [globalStore.modals, store],
    )

    return (
        <ListPage
            header={header}
            filter={filter}
            loading={!store.navigationItems.meta.initialized}
        >
            <DataGridProTable
                paginator={store.navigationItems}
                data={store.navigationItems.items}
                columns={columns}
                advancedOperations={{
                    pagination: "server",
                    filtering: "server",
                    sorting: "server",
                }}
                rowActionsRenderer={(item) => [
                    {
                        text: t`navigation-items-view.action.delete-item`,
                        destructive: true,
                        onClick: () => handleDeleteItem(item?.id),
                    },
                ]}
                onRowClickEvent={openDetailModalHandler}
                repository={repository}
                loading={!store.navigationItems.meta.initialized}
                onFilterChange={handleFilterChange}
                onSortChange={handleSortChange}
            />
        </ListPage>
    )
})

export const NavigationItemsView = () => (
    <StoreProvider Store={NavigationItemsStore}>
        <View />
    </StoreProvider>
)
