import { t } from "@lingui/macro"
import { observer } from "mobx-react"

import { EmptyMessage } from "src/modals/segment-picker/EmptyMessage"
import { SegmentPickerStore } from "src/modals/segment-picker/store"
import { useStore } from "src/store/lib/useStore"

export const NoSegmentsFound = observer(() => {
    const store = useStore(SegmentPickerStore)
    if (
        !store.availableSegments.meta.initialized ||
        store.availableSegments.items.length > 0
    ) {
        return null
    }

    if (store.scope === "full") {
        return (
            <EmptyMessage
                header={t`segment-picker.available.no-segments-found-header`}
                message={t`segment-picker.available.no-segments-found-global-message`}
            />
        )
    } else {
        return (
            <EmptyMessage
                header={t`segment-picker.available.no-segments-found-header`}
                message={t`segment-picker.available.no-segments-found-access-group-message`}
            />
        )
    }
})
