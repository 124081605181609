/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { avy_api_pkg_segment_PublishResourceRequest } from "../models/avy_api_pkg_segment_PublishResourceRequest"
import type { content_ContentAdminListResponse } from "../models/content_ContentAdminListResponse"
import type { content_ContentItem } from "../models/content_ContentItem"
import type { content_ContentItemListRequest } from "../models/content_ContentItemListRequest"
import type { content_CreateContentItemRequest } from "../models/content_CreateContentItemRequest"
import type { content_Module } from "../models/content_Module"
import type { content_UpdateContentItemRequest } from "../models/content_UpdateContentItemRequest"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class ContentAdminService {
    /**
     * Create content item
     * Create new content item
     * @returns content_ContentItem OK
     * @throws ApiError
     */
    public static postV1AdminContentItem({
        request,
    }: {
        /** Request body for content navigation item **/
        request: content_CreateContentItemRequest
    }): CancelablePromise<content_ContentItem> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/content/item",
            body: request,
        })
    }

    /**
     * Get navigation items
     * Get navigation items
     * @returns content_ContentAdminListResponse OK
     * @throws ApiError
     */
    public static postV1AdminContentItemList({
        request,
    }: {
        /** ContentItemListRequest **/
        request: content_ContentItemListRequest
    }): CancelablePromise<content_ContentAdminListResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/content/item/list",
            body: request,
        })
    }

    /**
     * Get content item
     * Get content item by ID
     * @returns content_ContentItem OK
     * @throws ApiError
     */
    public static getV1AdminContentItem({
        contentItemId,
    }: {
        /** Content item ID **/
        contentItemId: number
    }): CancelablePromise<content_ContentItem> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/content/item/{content_item_id}",
            path: {
                content_item_id: contentItemId,
            },
        })
    }

    /**
     * Update navigation item
     * Update navigation item by ID
     * @returns any OK
     * @throws ApiError
     */
    public static putV1AdminContentItem({
        contentItemId,
        request,
    }: {
        /** Content item ID **/
        contentItemId: number
        /** Request body for updating navigation item **/
        request: content_UpdateContentItemRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/content/item/{content_item_id}",
            path: {
                content_item_id: contentItemId,
            },
            body: request,
        })
    }

    /**
     * Delete navigation item
     * Delete navigation item by ID
     * @returns any OK
     * @throws ApiError
     */
    public static deleteV1AdminContentItem({
        contentItemId,
    }: {
        /** Content item ID **/
        contentItemId: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/content/item/{content_item_id}",
            path: {
                content_item_id: contentItemId,
            },
        })
    }

    /**
     * Publish content item
     * Publish content item by ID
     * @returns any OK
     * @throws ApiError
     */
    public static putV1AdminContentItemPublish({
        contentItemId,
        request,
    }: {
        /** Content item ID **/
        contentItemId: number
        /** Request body for publishing content item **/
        request: avy_api_pkg_segment_PublishResourceRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/content/item/{content_item_id}/publish",
            path: {
                content_item_id: contentItemId,
            },
            body: request,
        })
    }

    /**
     * Get modules
     * Get feature flags that correspond to modules
     * @returns content_Module OK
     * @throws ApiError
     */
    public static getV1AdminContentNavigationModule(): CancelablePromise<
        Array<content_Module>
    > {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/content/navigation/module",
        })
    }
}
