import { t } from "@lingui/macro"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { observer } from "mobx-react"

import { FormFieldSet } from "src/components/FormFieldSet"
import { FeatureConfigurationDetailStore } from "src/modals/feature-configuration-detail/store"
import { useStore } from "src/store/lib/useStore"
import { useUniqueId } from "src/lib/unique-id"
import {
    currencyValues,
    languageValues,
} from "src/modals/feature-configuration-detail/constants/marketValues"

export const MarketSection = observer(() => {
    const store = useStore(FeatureConfigurationDetailStore)
    const selectCurrencyId = useUniqueId()
    const selectActiveLanguagesId = useUniqueId()

    const renderSelectField = (
        fieldName: "currency" | "activeLanguages",
        labelId: string,
        values: typeof currencyValues | typeof languageValues,
        headerKey: string,
        labelKey: string,
    ) => (
        <FormFieldSet header={headerKey}>
            <FormControl fullWidth>
                <InputLabel id={labelId}>{labelKey}</InputLabel>
                <Select
                    labelId={labelId}
                    value={store.form.get(fieldName)}
                    onChange={store.form.setter(fieldName)}
                    label={labelKey}
                >
                    {Object.entries(values).map(([key, value]) => (
                        <MenuItem key={key} value={value}>
                            {key}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </FormFieldSet>
    )

    return (
        <>
            {renderSelectField(
                "currency",
                selectCurrencyId,
                currencyValues,
                t`feature-configuration-detail-modal.market-section.currency-header`,
                t`feature-configuration-detail-modal.market-section.currency-label`,
            )}
            {renderSelectField(
                "activeLanguages",
                selectActiveLanguagesId,
                languageValues,
                t`feature-configuration-detail-modal.market-section.language-header`,
                t`feature-configuration-detail-modal.market-section.language-label`,
            )}
        </>
    )
})
