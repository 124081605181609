import { AvyChatInput } from "src/components/Chat/Input"
import { AvyChatThread } from "src/components/Chat/Thread"
import {
    ChatContainer,
    ConversationContainer,
} from "src/components/Chat/styled"
import { TAvyChatProps } from "src/components/Chat/types/chat"

export const AvyChat = (props: TAvyChatProps) => {
    const {
        messages,
        handleSourceClick,
        boxWrapperStyles,
        variant,
        isTranslationEnabled,
    } = props

    return (
        <ChatContainer>
            <ConversationContainer>
                <AvyChatThread
                    messages={messages}
                    boxWrapperStyles={boxWrapperStyles}
                    handleSourceClick={handleSourceClick}
                    isTranslationEnabled={isTranslationEnabled}
                />
            </ConversationContainer>
            {variant === "chat" && (
                <AvyChatInput handleSubmit={props.handleSubmit} />
            )}
        </ChatContainer>
    )
}
