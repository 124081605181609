import { t } from "@lingui/macro"
import {
    alpha,
    Box,
    Button,
    Stack,
    styled,
    Tab,
    Tabs,
    useTheme,
} from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useMemo, useState } from "react"

import { Icon } from "src/components/icons"
import { Cross16 } from "src/components/icons/Cross16"
import { SegmentPickerSearch } from "src/modals/segment-picker/SegmentPickerSearch"
import { SegmentPickerSelected } from "src/modals/segment-picker/SegmentPickerSelected"

const BottomBar = styled(Stack)({
    flex: 0,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
})

const TabBar = styled(Tabs)(({ theme }) => ({
    flex: 0,
    minHeight: "56px",
    backgroundColor: alpha(theme.palette.utility.torquoiseFaded.main, 0.5),
    "& .MuiTabs-indicator": { backgroundColor: theme.palette.grey[900] },
}))

const TabButton = styled(Tab)(({ theme }) => ({
    color: theme.palette.grey[900],
    textTransform: "none",
    fontSize: "16px",
    minHeight: "56px",
    "&.Mui-selected": {
        color: theme.palette.grey[900],
    },
}))

const TabContent = styled(Box)({
    overflow: "auto",
    flex: 1,
    padding: "1rem",
})

interface IProps {
    onSave: () => void
    onClose: () => void
    initialSegmentIds: number[]
}

export const TabbedSegmentPicker = observer((props: IProps) => {
    const [tabIndex, setTabIndex] = useState(0)
    const theme = useTheme()

    const tabs = useMemo(
        () => [
            <SegmentPickerSearch />,
            <SegmentPickerSelected
                onSave={props.onSave}
                onClose={props.onClose}
                initialSegmentIds={props.initialSegmentIds}
            />,
        ],
        [props],
    )

    const handleTabChange = useCallback(
        (_: unknown, index: number) => setTabIndex(index),
        [],
    )

    return (
        <Stack height="100%">
            <TabBar
                value={tabIndex}
                onChange={handleTabChange}
                variant="fullWidth"
            >
                <TabButton
                    label={t`segment-picker.find-segments-tab`}
                    value={0}
                />
                <TabButton
                    label={t`segment-picker.selected-segments-tab`}
                    value={1}
                />
            </TabBar>
            <TabContent>{tabs[tabIndex]}</TabContent>
            <BottomBar
                direction="row"
                justifyContent="space-between"
                padding={2}
            >
                <Button
                    startIcon={
                        <Icon
                            icon={<Cross16 />}
                            size={20}
                            color={theme.palette.info.main}
                        />
                    }
                    color="info"
                    onClick={props.onClose}
                >
                    {t`segment-picker.bottom-bar.close`}
                </Button>
                <Button variant="contained" onClick={props.onSave}>
                    {t`segment-picker.bottom-bar.save-selected`}
                </Button>
            </BottomBar>
        </Stack>
    )
})
