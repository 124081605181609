import { memo } from "react"

import { Typography } from "@mui/material"

import { INavigationItemDetailModalViewProps } from "src/modals/navigation-items-detail/types/navigationItemDetailModalView"

export const NavigationIDSection = memo(
    ({ id }: INavigationItemDetailModalViewProps) => {
        return <Typography variant="subtitle2">{id ?? "-"}</Typography>
    },
)
