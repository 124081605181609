import { t } from "@lingui/macro"
import {
    Box,
    Checkbox,
    ListItem as MUIListItem,
    ListItemText as MUIListItemText,
    styled,
} from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { HighlightedQuery } from "src/components/HighlightedQuery"
import { ListItemBreadcrumb } from "src/modals/segment-picker/SegmentPickerSearch/ListItemBreadcrumb"
import { SelectionStatusChip } from "src/modals/segment-picker/SegmentPickerSearch/SelectionStatusChip"
import { SegmentTypeIcon } from "src/modals/segment-picker/SegmentTypeIcon"

import { SegmentPickerStore } from "src/modals/segment-picker/store"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

interface IProps {
    segment: ISegmentForSearch
}

const ListItemInner = styled(MUIListItem)(({ theme }) => ({
    background: theme.palette.grey[100],
    marginBottom: "2px",
    borderRadius: "4px",
    padding: 0,
    overflow: "hidden",
    alignItems: "stretch",
    paddingRight: "90px",
}))

const ListItemText = styled(MUIListItemText)({
    [`& .MuiListItemText-secondary`]: {
        lineHeight: "inherit",
    },
})

const SegmentNameButton = styled("button")(({ theme }) => ({
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.grey[900],
    lineHeight: 1.4,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    border: "none",
    background: "transparent",
    padding: 0,
    cursor: "pointer",
    maxWidth: "100%",
    display: "inherit",
    "&:hover": {
        textDecoration: "underline",
    },
}))

const SegmentNameStatic = styled("span")(({ theme }) => ({
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.grey[900],
    lineHeight: 1.4,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    border: "none",
    background: "transparent",
    maxWidth: "100%",
    display: "inherit",
    padding: 0,
}))

export const ListItem = observer((props: IProps) => {
    const store = useStore(SegmentPickerStore)
    const globalStore = useStore(GlobalStore)

    const { segment } = props

    const handleParentClick = useCallback(async () => {
        store.setParent(segment)
        await store.availableSegments.loadInitialPage()
    }, [store, segment])

    const handleCheckBoxClick = useCallback(() => {
        if (globalStore.session.selectedParakeyType === "Private") {
            store.selectOneSegment(segment)
        } else {
            store.selectSegment(segment)
        }
    }, [globalStore.session.selectedParakeyType, store, segment])

    return (
        <ListItemInner
            secondaryAction={
                <SelectionStatusChip
                    selected={store.hasSelectedSegment(segment)}
                    hasSelectedAncestors={store.hasSelectedAncestorSegment(
                        segment,
                    )}
                    tenantCount={segment.tenantCount}
                />
            }
        >
            <Checkbox
                onChange={(_, checked) =>
                    checked
                        ? handleCheckBoxClick()
                        : store.deselectSegment(segment)
                }
                checked={store.hasSelectedSegment(segment)}
                title={
                    store.hasSelectedSegment(segment)
                        ? t({
                              id: "segment-picker.available.deselect-segment",
                              values: { name: segment.name },
                          })
                        : t({
                              id: "segment-picker.available.select-segment",
                              values: { name: segment.name },
                          })
                }
                sx={{
                    background: "#eee",
                    borderRadius: 0,
                    padding: "4px",
                }}
                data-testid="asdfa"
            />
            <Box
                sx={{
                    margin: "0 8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <SegmentTypeIcon type={segment.type} size={20} />
            </Box>
            <ListItemText
                primary={
                    store.hasChildSegmentType(segment) ? (
                        <SegmentNameButton
                            title={segment.name}
                            onClick={handleParentClick}
                        >
                            <HighlightedQuery
                                text={segment.name}
                                query={store.query}
                            />
                        </SegmentNameButton>
                    ) : (
                        <SegmentNameStatic>
                            <HighlightedQuery
                                text={segment.name}
                                query={store.query}
                            />
                        </SegmentNameStatic>
                    )
                }
                secondary={<ListItemBreadcrumb segment={segment} />}
            />
        </ListItemInner>
    )
})
