import { t } from "@lingui/macro"
import { useCallback, useState } from "react"
import { Button, Alert } from "@mui/material"
import { observer } from "mobx-react"

import { useNavigate, useLocation } from "react-router-dom"

import { INavigationItemDetailModalViewProps } from "./types/navigationItemDetailModalView"

import { ModalHeader } from "src/components/ModalHeader"
import { NavigationItemDetailStore } from "src/modals/navigation-items-detail/store"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { useInitializer } from "src/lib/initializer"
import { CenteredSpinner } from "src/components/CenteredSpinner"

import { Form } from "src/components/Form"
import { FormPanel } from "src/components/FormPanel"

import { NavigationIDSection } from "src/modals/navigation-items-detail/sections/NavigationIDSection"
import { SettingsSection } from "src/modals/navigation-items-detail/sections/SettingsSection"
import { ContentSection } from "src/modals/navigation-items-detail/sections/ContentSection"
import { PublishingSettingsSection } from "src/modals/navigation-items-detail/sections/PublishingSettingsSection"
import { removeLastIdSegment } from "src/helpers/removeLastIdSegment"
import IntegrationContentSection from "src/modals/navigation-items-detail/sections/IntegrationContentSection"

interface IExtraData {
    accessType?: string
}

const View = observer(({ id }: INavigationItemDetailModalViewProps) => {
    const globalStore = useStore(GlobalStore)
    const navDetailStore = useStore(NavigationItemDetailStore)

    const navigate = useNavigate()
    const location = useLocation()
    const locationState = location.state as IExtraData
    const parentPath = removeLastIdSegment(location.pathname)

    const [thisModal] = useState(
        globalStore.modals.active[globalStore.modals.active.length - 1],
    )

    thisModal.confirmOnOverlayClick = () => {
        if (!navDetailStore.form.getIsDirty()) {
            navigate(parentPath)
        }
        return navDetailStore.form.getIsDirty()
    }

    const initialized = useInitializer(async () => {
        await navDetailStore.init(
            id,
            globalStore.session.accessGroupId,
            locationState?.accessType,
        )
    }, [
        globalStore.session.accessGroupId,
        id,
        navDetailStore,
        locationState?.accessType,
    ])

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            const validationError = await navDetailStore.handleSubmit(
                globalStore.session.accessGroup?.name,
                globalStore.session.accessGroup?.id,
            )

            if (
                validationError &&
                !Boolean(navDetailStore.form.error("generic"))
            ) {
                navigate(parentPath)
                globalStore.modals.pop()
            }
        },
        [globalStore, navDetailStore, navigate, parentPath],
    )

    if (!initialized) {
        globalStore.loading.is(NavigationItemDetailStore.LoadingKeys.init)
        return <CenteredSpinner height="100vh" />
    }

    const isSaveButtonDisabled =
        navDetailStore.form.getIsDirty() === false ||
        navDetailStore.isLoading ||
        navDetailStore.isReadOnly

    return (
        <Form onSubmit={handleSubmit}>
            <ModalHeader path={parentPath}>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={isSaveButtonDisabled}
                >
                    {t`global.save`}
                </Button>
            </ModalHeader>
            {Boolean(navDetailStore.form.error("generic")) && (
                <Alert severity="error">
                    {navDetailStore.form.error("generic")}
                </Alert>
            )}
            <FormPanel
                sections={[
                    {
                        header: t`navigation-items-view.form-panel.navigation-id`,
                        content: <NavigationIDSection id={id} />,
                    },
                ]}
            />
            <FormPanel
                sections={[
                    {
                        header: t`navigation-items-view.form-panel.settings`,
                        content: <SettingsSection />,
                    },
                ]}
            />

            <FormPanel
                sections={[
                    {
                        header: t`navigation-items-view.form-panel.publishing-settings`,
                        content: <PublishingSettingsSection />,
                    },
                ]}
            />
            {(navDetailStore.form.get("showInMainNavigation") ||
                navDetailStore.form.get("showInOverview")) &&
                navDetailStore.form.get("entity") !==
                    "integration_connector" && (
                    <FormPanel
                        sections={[
                            {
                                header: t`navigation-items-view.form-panel.content`,
                                content: <ContentSection />,
                            },
                        ]}
                    />
                )}
            {navDetailStore.form.get("entity") === "integration_connector" && (
                <FormPanel
                    sections={[
                        {
                            header: t`navigation-items-view.form-panel.content`,
                            content: <IntegrationContentSection />,
                        },
                    ]}
                />
            )}
        </Form>
    )
})

export const NavigationItemModalView = (
    props: INavigationItemDetailModalViewProps,
) => (
    <StoreProvider Store={NavigationItemDetailStore}>
        <View {...props} />
    </StoreProvider>
)
