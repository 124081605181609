/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from "react"
import {
    Routes as ReactRouterDOMRoutes,
    Route,
    useLocation,
} from "react-router-dom"

import { AuthLayout } from "src/layouts/AuthLayout"
import { DefaultLayout } from "src/layouts/DefaultLayout"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { AccessGroupsView } from "src/views/access-groups"
import { BookingsView } from "src/views/bookings"
import { CampaignsView } from "src/views/campaigns"
import { ClientManagementView } from "src/views/client-management"
import { CommunitiesView } from "src/views/communities"
import { CommunityCommentsView } from "src/views/community-comments"
import { CommunityPostsView } from "src/views/community-posts"
import { ContactFormSettingsView } from "src/views/contact-form-settings"
import { ContactFormsView } from "src/views/contact-forms"
import { EmbeddedIntegrationsView } from "src/views/embedded-integrations"
import { EmbeddedIntegrationsView2 } from "src/views/embedded-integrations-v2"
import { FeatureConfigurationsView } from "src/views/feature-configurations"
import { FeatureTogglesView } from "src/views/feature-toggles"
import { ForgotPasswordView } from "src/views/forgot-password"
import { InvoicesView } from "src/views/invoices"
import { LibraryInformationItemsView } from "src/views/library-information-items"
import { LoginView } from "src/views/login"
import { ManageUsersView } from "src/views/manage-users"
import { NewPasswordView } from "src/views/new-password"
import { NoticeBoardView } from "src/views/notice-board"
import { OrdersView } from "src/views/orders"
import { OrdersIndividualAdditionsView } from "src/views/orders/individual-additions"
import { ProductCatalogueView } from "src/views/product-catalogue"
import { EditProductView } from "src/views/product-catalogue/edit"
import { ProductCategoriesView } from "src/views/product-categories"
import { ProvidersView } from "src/views/providers"
import { ResourcesView } from "src/views/resources"
import { SelfRegistrationView } from "src/views/self-registration"

import { PageNotFoundView } from "src/components/PageNotFound"
import { environment } from "src/config/variables"
import { trackNavigationMenuEvents } from "src/analytics/helpers/mixpanel_tracking"
import { AccessView } from "src/views/access"
import { AutoAssignView } from "src/views/auto-assignee"
import { ChatbotConversation } from "src/views/chatbot"
import { FeaturesView } from "src/views/feature-toggles/features"
import { IntegrationConnectorView } from "src/views/integration-connector"
import { ResetView } from "src/views/reset_endpoints"
import { TenantsDetailView } from "src/views/tenants/details-invoices"
import { TenantsListView } from "src/views/tenants/list"
import { FormsView } from "src/views/contact-form-formsList"
import { getLocationName } from "src/analytics/helpers/getLocationName"
import { ChatbotDispatchesView } from "src/views/chatbot-dispatches"
import { LibraryCategoriesView } from "src/views/library-categories"
import { TagsView } from "src/views/tags"
import { ChatbotInsightsView } from "src/views/chatbot-insights"
import { TenantsInsightsView } from "src/views/tenants-insights"
import { CasesView } from "src/views/cases-v2"
import { NavigationItemsView } from "src/views/navigation-items"
import { CommunityOverviewView } from "src/views/community-overview"
import { CommunityInsightsView } from "src/views/community-insights"
import { OmniDomainsView } from "src/views/omni-domains"
import { CommunityPostsAndCommentsView } from "src/views/community-posts-and-comments"
import { ConfiguringAgentsPersonaView } from "src/views/configuring-agents-persona"
import { CommunityBlockedTenantsView } from "src/views/community-blocked-tenants"
import { ContentItemsView } from "src/views/content-items"

export function Routes() {
    const globalStore = useStore(GlobalStore)
    const location = useLocation()

    useEffect(() => {
        trackNavigationMenuEvents(
            getLocationName(location.pathname),
            globalStore.session.accessGroup,
            globalStore.session.user?.adminId,
        )
    }, [location.pathname])

    const isSuperUser = globalStore.session.user?.isSuper === true
    const hasAccessTo = useCallback(
        (...modules: Modules[]) => {
            return modules.some((module) =>
                globalStore.session.hasAccessToModule(module),
            )
        },
        [globalStore.session],
    )

    return (
        <ReactRouterDOMRoutes>
            <Route element={<AuthLayout />}>
                <Route path={"/login/*"} element={<LoginView />} />
                <Route
                    path={"/forgot-password"}
                    element={<ForgotPasswordView />}
                />
                <Route path={"/new-password"} element={<NewPasswordView />} />
            </Route>
            <Route element={<DefaultLayout />}>
                {hasAccessTo("dashboard") && (
                    <Route
                        path="tenants-insights"
                        element={<TenantsInsightsView />}
                    />
                )}
                {hasAccessTo("notice_board") && (
                    <Route>
                        <Route
                            path="/notice-board"
                            element={<NoticeBoardView />}
                        />
                        <Route
                            path="/notice-board/:noticeBoardId"
                            element={<NoticeBoardView />}
                        />
                    </Route>
                )}
                {hasAccessTo("booking") && (
                    <Route path="/booking">
                        <Route path="resources" element={<ResourcesView />} />
                        <Route path="bookings" element={<BookingsView />} />
                    </Route>
                )}
                {hasAccessTo("provider") && (
                    <Route path="/providers" element={<ProvidersView />} />
                )}
                {hasAccessTo("spotlight") && (
                    <Route path="/campaigns" element={<CampaignsView />} />
                )}
                {hasAccessTo("tenants") && (
                    <Route path="/tenants" element={<TenantsListView />} />
                )}
                {hasAccessTo("tenants") && (
                    <Route
                        path="/tenants/:tenantId"
                        element={<TenantsDetailView />}
                    />
                )}
                {hasAccessTo("product_catalogue") && (
                    <>
                        <Route path="/order">
                            <Route path="orders" element={<OrdersView />} />
                            <Route
                                path="individual-additions"
                                element={<OrdersIndividualAdditionsView />}
                            />
                        </Route>
                        <Route path="/product-catalogue">
                            <Route path="products">
                                <Route
                                    index
                                    element={<ProductCatalogueView />}
                                />
                                <Route
                                    path="new"
                                    element={<EditProductView />}
                                />
                                <Route
                                    path=":productId"
                                    element={<EditProductView />}
                                />
                            </Route>
                            <Route
                                path="categories"
                                element={<ProductCategoriesView />}
                            />
                        </Route>
                    </>
                )}
                {hasAccessTo("contact_form") && (
                    <Route path="/case/cases" element={<ContactFormsView />} />
                )}
                {hasAccessTo("contact_form") && (
                    <Route path="/case/cases-v2" element={<CasesView />} />
                )}

                {hasAccessTo("navigation_items") && (
                    <>
                        <Route
                            path={"/user-interface/navigation-items"}
                            element={<NavigationItemsView />}
                        />
                        <Route
                            path={"/user-interface/navigation-items/:id"}
                            element={<NavigationItemsView />}
                        />
                        {environment !== "production" && (
                            <>
                                <Route
                                    path={"/user-interface/content-items"}
                                    element={<ContentItemsView />}
                                />
                                <Route
                                    path={"/user-interface/content-items/:id"}
                                    element={<ContentItemsView />}
                                />
                            </>
                        )}
                    </>
                )}

                {hasAccessTo("contact_form_type") && (
                    <Route path="/case/forms" element={<FormsView />} />
                )}
                {hasAccessTo("contact_form_type") && (
                    <Route
                        path="/case/settings"
                        element={<ContactFormSettingsView />}
                    />
                )}
                {hasAccessTo("contact_form_default_assignee") && (
                    <Route
                        path="/case/automatic-assignee"
                        element={<AutoAssignView />}
                    />
                )}
                {hasAccessTo("ai_chatbot") && (
                    <>
                        <Route
                            path="/chatbot/insights"
                            element={<ChatbotInsightsView />}
                        />
                        <Route
                            path="/chatbot/conversation"
                            element={<ChatbotConversation />}
                        />
                        <Route
                            path="/chatbot/conversation/:conversationId"
                            element={<ChatbotConversation />}
                        />
                        <Route
                            path="/chatbot/dispatches"
                            element={<ChatbotDispatchesView />}
                        />
                    </>
                )}
                {hasAccessTo("ai_chatbot") && environment !== "production" && (
                    <>
                        <Route
                            path={"/chatbot/agents"}
                            element={<ConfiguringAgentsPersonaView />}
                        />
                        <Route
                            path={"/chatbot/agents/:id"}
                            element={<ConfiguringAgentsPersonaView />}
                        />
                    </>
                )}
                {hasAccessTo("tags") && (
                    <Route path="/chatbot/tags" element={<TagsView />} />
                )}
                {hasAccessTo("locks") && (
                    <Route path="/access" element={<AccessView />} />
                )}
                {hasAccessTo("usermanagement") && (
                    <>
                        <Route
                            path="/manage-users"
                            element={<ManageUsersView />}
                        />
                        <Route
                            path="/access-groups"
                            element={<AccessGroupsView />}
                        />
                    </>
                )}
                {hasAccessTo("community_v2") && (
                    <>
                        <Route
                            path="/community/overview"
                            element={<CommunityOverviewView />}
                        />
                        <Route
                            path="/community/posts-and-comments"
                            element={<CommunityPostsAndCommentsView />}
                        />
                    </>
                )}
                {hasAccessTo("community_insights") && (
                    <>
                        <Route
                            path="/community/insights"
                            element={<CommunityInsightsView />}
                        />
                    </>
                )}
                {hasAccessTo("community") && (
                    <>
                        <Route
                            path="/community/posts-and-comments?params"
                            element={<CommunityPostsAndCommentsView />}
                        />
                        <Route
                            path="/community/communities"
                            element={<CommunitiesView />}
                        />
                        <Route
                            path="/community/posts"
                            element={<CommunityPostsView />}
                        />
                        <Route
                            path="/community/comments"
                            element={<CommunityCommentsView />}
                        />
                        <Route
                            path="/community/blocked-users"
                            element={<CommunityBlockedTenantsView />}
                        />
                    </>
                )}
                {hasAccessTo("lobby", "self_registration_leaflet") && (
                    <Route
                        path="/self-registration"
                        element={<SelfRegistrationView />}
                    >
                        <Route
                            path="/self-registration/:tab"
                            element={<SelfRegistrationView />}
                        />
                    </Route>
                )}
                {hasAccessTo("info_center") && (
                    <Route
                        path="/library/information-items"
                        element={<LibraryInformationItemsView />}
                    >
                        <Route
                            path="/library/information-items/:informationItemId"
                            element={<LibraryInformationItemsView />}
                        />
                    </Route>
                )}
                {hasAccessTo("info_center_category") && (
                    <Route
                        path="/library/categories"
                        element={<LibraryCategoriesView />}
                    />
                )}
                {hasAccessTo("invoices") && (
                    <Route path="/invoices" element={<InvoicesView />} />
                )}
                {(isSuperUser || hasAccessTo("integration_connector")) && (
                    <Route
                        path="/integrations/integration-connector"
                        element={<IntegrationConnectorView />}
                    />
                )}
                {isSuperUser && environment !== "production" && (
                    <Route path="/reset" element={<ResetView />} />
                )}
                {isSuperUser && (
                    <Route path="/omni-domains" element={<OmniDomainsView />} />
                )}

                {hasAccessTo("embedded_integrations") && (
                    <Route
                        path="/integrations/embedded-integrations-v2"
                        element={<EmbeddedIntegrationsView2 />}
                    />
                )}

                {isSuperUser && (
                    <>
                        <Route
                            path="/client-management"
                            element={<ClientManagementView />}
                        >
                            <Route
                                path="/client-management/:tab"
                                element={<ClientManagementView />}
                            />
                            <Route
                                path="/client-management/:tab/:propertyOwnerId"
                                element={<ClientManagementView />}
                            />
                            <Route
                                path="/client-management/:tab/:propertyOwnerId/:legalEntityId"
                                element={<ClientManagementView />}
                            />
                        </Route>
                        <Route
                            path="/feature-toggles/feature-configurations"
                            element={<FeatureConfigurationsView />}
                        />
                        <Route
                            path="/feature-toggles"
                            element={<FeatureTogglesView />}
                        />
                        <Route
                            path="/feature-toggles/features"
                            element={<FeaturesView />}
                        />
                        <Route
                            path="/integrations/embedded-integrations"
                            element={<EmbeddedIntegrationsView />}
                        />
                    </>
                )}
                <Route path="/" element={<AuthenticatedView />} />

                <Route path="*" element={<PageNotFoundView />} />
            </Route>
        </ReactRouterDOMRoutes>
    )
}

const AuthenticatedView = () => {
    const gstore = useStore(GlobalStore)

    useEffect(() => {
        // Do a full load redirect here as we still have routes going to the old
        // admin. When we all pages have been migrated to this app we can do a
        // pushState redirect.
        window.location.href = gstore.navigation.initialPageUrl
    }, [gstore.navigation.initialPageUrl])

    return null
}
