import { t } from "@lingui/macro"
import {
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    SelectChangeEvent,
    SxProps,
} from "@mui/material"
import { observer } from "mobx-react"
import { useEffect, useState } from "react"

import { useStore } from "src/store/lib/useStore"

import { locationItemsOptionList } from "src/views/content-items/constants/locationItemsOptionList"
import { ContentItemsStore } from "src/views/content-items/store"

const CONTAINER_WIDTH = 250

const selectStyle: SxProps = {
    width: CONTAINER_WIDTH,
}

export const LocationFilterSelector = observer(() => {
    const store = useStore(ContentItemsStore)
    const [selectedValue, setSelectedValue] = useState<string>("")

    const locationLabel = t`navigation-items-view.filter-location`

    useEffect(() => {
        setSelectedValue(store.locationFilterSelectedValue)
    }, [store.locationFilterSelectedValue])

    const handleOnChange = (event: SelectChangeEvent<string>) => {
        setSelectedValue(event.target.value)
        store.setLocationsFilterSelected(event.target.value)
    }

    return (
        <FormControl sx={selectStyle}>
            <InputLabel
                id="multi-select-dropdown-label"
                data-testid="multi-select-dropdown-label"
            >
                {locationLabel}
            </InputLabel>
            <Select
                labelId="location-select-label"
                label={locationLabel}
                id="filter-location"
                autoWidth
                onChange={handleOnChange}
                sx={selectStyle}
                value={selectedValue}
                placeholder={locationLabel}
            >
                {Object.entries(locationItemsOptionList).map(
                    ([location, label]) => (
                        <MenuItem
                            key={location}
                            value={location}
                            sx={selectStyle}
                        >
                            {label as string}
                        </MenuItem>
                    ),
                )}
            </Select>
        </FormControl>
    )
})
