import { Box, TextField, useTheme } from "@mui/material"
import { useMemo } from "react"

import { TooltipWithIcon } from "src/components/Tooltips/TooltipWithIcon"
import { IModalTextField } from "src/types/modal-text-field"

export const ModalTextField = (props: IModalTextField) => {
    const theme = useTheme()
    const backgroundColor =
        props.disabled === true
            ? theme.palette.grey[300]
            : theme.palette.common.white

    const multiline = useMemo(
        () =>
            (props.rows !== undefined && props.rows > 1) ||
            (props.maxRows !== undefined && props.maxRows > 1),
        [props.rows, props.maxRows],
    )

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange !== undefined) {
            const trimmedValue = event.target.value.trimStart()
            props.onChange({
                ...event,
                target: { ...event.target, value: trimmedValue },
            } as React.ChangeEvent<HTMLInputElement>)
        }
    }

    return (
        <Box
            sx={{ display: "flex", gap: "8px" }}
            style={{
                breakInside: "avoid",
                alignItems: "center",
            }}
        >
            <TextField
                label={props.label}
                placeholder={props.placeholder}
                onChange={handleChange}
                value={props.value ?? ""}
                helperText={props.helperText}
                error={props.error}
                disabled={props.disabled}
                rows={props.rows}
                multiline={multiline}
                maxRows={props.maxRows}
                style={{ backgroundColor }}
                inputProps={{
                    maxLength: props.maxLength,
                }}
                size={props.size}
                data-testid="modal-form-text-field"
            />
            {props.variant === "withToolTip" && (
                <div>
                    <TooltipWithIcon tooltipText={props.tooltipText} />
                </div>
            )}
        </Box>
    )
}
