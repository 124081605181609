import React, { useMemo } from "react"
import { Box, TextField, useTheme } from "@mui/material"

import { TooltipWithIcon } from "src/components/Tooltips/TooltipWithIcon"
import { INumberInputProps } from "src/types/modal-number-field"

export const ModalNumberField: React.FC<INumberInputProps> = ({
    label,
    value,
    onChange,
    disabled = false,
    min,
    max,
    helperText,
    error = false,
    placeholder,
    maxLength,
    size,
    tooltipText,
}) => {
    const theme = useTheme()
    const backgroundColor = disabled
        ? theme.palette.grey[300]
        : theme.palette.common.white

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value

        // Allow empty string or valid number
        if (newValue === "" || /^\d+$/.test(newValue)) {
            const numValue = newValue === "" ? null : parseInt(newValue, 10)

            // Check min and max constraints
            if (
                (min === undefined || numValue === null || numValue >= min) &&
                (max === undefined || numValue === null || numValue <= max)
            ) {
                onChange(event)
            }
        }
    }

    const inputProps = useMemo(
        () => ({
            maxLength,
            min,
            max,
            inputMode: "numeric" as const,
            pattern: "[0-9]*",
        }),
        [maxLength, min, max],
    )

    return (
        <Box
            sx={{ display: "flex", gap: "8px" }}
            style={{
                breakInside: "avoid",
                backgroundColor,
            }}
        >
            <TextField
                label={label}
                placeholder={placeholder}
                onChange={handleChange}
                value={value}
                helperText={helperText}
                error={error}
                disabled={disabled}
                inputProps={inputProps}
                size={size}
                data-testid="modal-form-number-field"
            />
            {tooltipText != null && (
                <div>
                    <TooltipWithIcon tooltipText={tooltipText} />
                </div>
            )}
        </Box>
    )
}

export default ModalNumberField
