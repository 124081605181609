import { makeAutoObservable } from "mobx"
import { t } from "@lingui/macro"

import React from "react"

import { FormFields } from "src/lib/form-fields"
import { createLoadingKeys } from "src/lib/loading"
import { loads } from "src/channel/utils"
import {
    ConnectorAdminService,
    EmbedAdminService,
    avy_api_internal_utils_db_JSONB,
    avy_api_pkg_embed_Integration,
    avy_api_pkg_embed_SalesData,
    avy_api_pkg_embed_Segment,
} from "src/api"
import { Channel } from "src/channel"
import { EmbeddedIntegrationSettingType } from "src/modals/embedded-integration-v2/types/contentDynamicFields"
import { IEmbeddedIntegrationFormFields } from "src/modals/embedded-integration-v2/types/embeddedIntegrationFormFields"
import { avy_api_pkg_embed_IntegrationCreateUpdate } from "src/api/models/avy_api_pkg_embed_IntegrationCreateUpdate"
import { avy_api_pkg_embed_StaticPurchaseDataMap } from "src/api/models/avy_api_pkg_embed_StaticPurchaseDataMap"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { IIntegrationConnectorItem } from "src/views/integration-connector/type"

export class EmbeddedIntegrationStore {
    static Context = React.createContext<EmbeddedIntegrationStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "submit")

    private id?: number
    _generalCategoryFields: avy_api_internal_utils_db_JSONB = {}
    _saleTabFields: avy_api_pkg_embed_SalesData = {}
    _purchaseTabFields: avy_api_pkg_embed_StaticPurchaseDataMap = {}
    hasError: boolean = false

    private _accessGroupId?: number = DEFAULT_ACCESS_GROUP.id

    //#region getters
    get accessGroupId() {
        return this._accessGroupId
    }

    get isEditMode() {
        return this.id !== undefined
    }

    getTypesList = () => [
        {
            id: EmbeddedIntegrationSettingType.Simple,
            label: EmbeddedIntegrationSettingType.Simple,
        },
        {
            id: EmbeddedIntegrationSettingType.Subscription,
            label: EmbeddedIntegrationSettingType.Subscription,
        },
    ]

    async getPartners() {
        const response = await EmbedAdminService.getV1AdminEmbedPartner()
        const partnersArrayList = response.map((item) => ({
            id: item.id,
            label: item.name,
        }))
        this.fields.set("partnerList", partnersArrayList)
    }

    async getIntegrationDataByType(type: string) {
        const response =
            await ConnectorAdminService.getV1AdminConnectorIntegration({ type })

        return response.map((item) => ({
            id: item.integration_id as number,
            accessGroupId: item.access_group_id as number,
            accessType: item.access_type as string,
            createdAt: item.created_at as string,
            deletedAt: item.deleted_at as string,
            integrationId: item.integration_id as number,
            integrationTypeId: item.integration_type_id as number,
            metaData: item.metadata as Record<string, string>,
            name: item.name as string,
            label: item.name as string,
            type: item.type as string,
            updatedAt: item.updated_at as string,
        }))
    }

    async getMultipleTypeIntegrations() {
        const types = ["infometric", "ecoguard", "pigello"]

        const allConnectorLists = await Promise.all(
            types.map(this.getIntegrationDataByType),
        )

        const flattenedConnectorList: IIntegrationConnectorItem[] =
            allConnectorLists.flat()

        this.fields.set("connectorList", flattenedConnectorList)
    }

    //#endregion

    //#region setters
    private setId(id?: number) {
        this.id = id
    }

    get formId() {
        return this.id
    }

    setAccessGroupId(id?: number) {
        this._accessGroupId = id
    }

    setHasError = (hasError: boolean) => (this.hasError = hasError)

    setGeneralCategoryFields = (
        categoryFields: avy_api_internal_utils_db_JSONB,
    ) => (this._generalCategoryFields = categoryFields)

    setSaleTabFields = (saleTabFields: avy_api_pkg_embed_SalesData) =>
        (this._saleTabFields = saleTabFields)

    setPurchaseTabFields = (
        purchaseTabFields: avy_api_pkg_embed_StaticPurchaseDataMap,
    ) => (this._purchaseTabFields = purchaseTabFields)

    //#endregion

    fields = new FormFields<IEmbeddedIntegrationFormFields>({
        segmentIds: [],
        partnerList: [],
        connectorList: [],
        selectedConnectorId: -1,
    })

    constructor() {
        makeAutoObservable(this)
    }

    //#region store operations
    @loads(() => EmbeddedIntegrationStore.LoadingKeys.init)
    async init(
        accessGroupId: number,
        id?: number,
        segments?: avy_api_pkg_embed_Segment[],
    ) {
        await this.getPartners()
        await this.getMultipleTypeIntegrations()
        this.setId(id)
        this.setAccessGroupId(accessGroupId)

        let response: avy_api_pkg_embed_Integration | undefined
        if (id !== undefined) {
            response = await EmbedAdminService.getV1AdminEmbedIntegration1({
                integrationId: id,
            })
            this.setGeneralCategoryFields(
                response.category_fields as avy_api_internal_utils_db_JSONB,
            )
            this.setSaleTabFields(
                (response.sales_data as avy_api_pkg_embed_SalesData) ?? {},
            )
            if (response.static_purchase_data != null) {
                this.setPurchaseTabFields(
                    response.static_purchase_data as avy_api_pkg_embed_StaticPurchaseDataMap,
                )
            }
        }

        if (id !== null) {
            this.initFields({
                partnerList: this.fields.data.partnerList,
                connectorList: this.fields.data.connectorList,
                selectedConnectorId: response?.integration_connector_id,
                internalName: response?.name ?? "",
                referenceId: response?.code ?? "",
                singleSignOn: response?.sso_enabled ?? false,
                openExternallyInApp: response?.open_externally ?? false,
                openExternallyInWeb: response?.open_externally_web ?? false,
                useSalesPage: response?.show_sales_screen ?? false,
                entryUrl: response?.entry_url ?? "",
                externalLink: response?.external_links_regex ?? "",
                partner: String(response?.partner_id) ?? "",
                type: response?.type,
                accessGroupId: response?.access_group_id ?? this._accessGroupId,
                segmentIds:
                    segments != null
                        ? segments.map((item) => item.segment_id ?? 0)
                        : [],
            })
        }

        /**
         * useInitializer/@loads is designed to handle async functions
         * and that is the case everywhere else, so handling this by returning a promise so as to
         * handle mounting of segmentPicker only after initialization
         **/
        return Promise.resolve()
    }

    @loads(() => EmbeddedIntegrationStore.LoadingKeys.submit)
    async submit(request: avy_api_pkg_embed_IntegrationCreateUpdate) {
        const { id } = this
        if (this._accessGroupId == null) {
            this.fields.setErrors({ accessGroupId: t`errors.required` })
            return
        }
        await this.fields.catchErrors(async () => {
            const { data } = this.fields

            if (id !== undefined) {
                await EmbedAdminService.putV1AdminEmbedIntegration({
                    integrationId: id ?? 0,
                    request,
                })
                await EmbedAdminService.putV1AdminEmbedIntegrationPublish({
                    integrationId: id ?? 0,
                    request: {
                        segment_ids: data.segmentIds,
                    },
                })
                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "embed-integrations",
                        action: "update",
                        item: {
                            id: id ?? 0,
                            name: "Segments update",
                        },
                    },
                })
            } else {
                const response =
                    await EmbedAdminService.postV1AdminEmbedIntegration({
                        request: {
                            ...request,
                            access_group_id: this._accessGroupId,
                        },
                    })
                await EmbedAdminService.putV1AdminEmbedIntegrationPublish({
                    integrationId: response.id ?? 0,
                    request: {
                        segment_ids: data.segmentIds,
                    },
                })
                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "embed-integrations",
                        action: "create",
                    },
                })
            }
        })
    }
    //#endregion

    private initFields(fields: IEmbeddedIntegrationFormFields) {
        this.fields.init(fields)
    }

    validateRequiredFields = (requiredFields: string[]) => {
        this.fields.clearErrors()

        requiredFields.forEach((field) => {
            const requiredField = field as keyof IEmbeddedIntegrationFormFields
            const formField = this.fields.get(requiredField)

            if (
                formField == null ||
                formField.toString().trim() === "" ||
                formField === "<p></p>\n"
            ) {
                this.fields.setError(requiredField, t`errors.required`)
            } else {
                this.fields.clearError(requiredField)
            }
        })
    }
}
