import { Stack, styled, Typography } from "@mui/material"
import { observer } from "mobx-react"

const Text = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[900],
    fontSize: "12px",
    lineHeight: 1.8,
    width: "220px",
    textAlign: "center",
    marginTop: "32px",
}))

interface IProps {
    header: string
    message: string
}

export const EmptyMessage = observer((props: IProps) => (
    <Stack spacing={2} alignItems="center">
        <Text>{props.header}</Text>
        <Text>{props.message}</Text>
    </Stack>
))
