import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo } from "react"
import { Stack } from "@mui/material"

import { useParams, useNavigate } from "react-router-dom"

import { ContentItemsStore } from "./store"

import { DataGridProTable } from "src/components/Table/DataGridPro"
import { useStore } from "src/store/lib/useStore"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { Repository } from "src/types/channel"
import { GlobalStore } from "src/store"
import { IPageFilterProps } from "src/components/PageFilter"
import { SegmentPickerButton } from "src/components/SegmentPickerButton"
import { ListPage } from "src/components/ListPage"
import { FilterModel, SortModel } from "src/types/data-grid-pro"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"
import { NavigationItemModalView } from "src/modals/navigation-items-detail"
import {
    getFilterModelForRepository,
    getSortModelForRepository,
} from "src/lib/data-grid-pro"
import { ConfirmModal } from "src/modals/confirm"
import { useInitializer } from "src/lib/initializer"
import { LocationFilterSelector } from "src/views/content-items/components/LocationFilterSelect"
import { useContentItemColumns } from "src/views/content-items/hooks/useContentItemColumns"

const repository: Repository = "content-items"

const View = observer(() => {
    const { id } = useParams()
    const store = useStore(ContentItemsStore)
    const globalStore = useStore(GlobalStore)
    const columns = useContentItemColumns()
    const navigate = useNavigate()

    useEffect(() => {
        ;(async () => {
            await store.refresh()
        })()
    }, [store, store.locationFilterSelectedValue])

    useInitializer(async () => {
        if (id !== undefined && !isNaN(Number(id))) {
            await globalStore.modals.open(() => (
                <NavigationItemModalView id={Number(id)} />
            ))
        }
    }, [id, globalStore])

    const handleNewNavigationItemClick = useCallback(() => {
        globalStore.modals.open(() => <NavigationItemModalView />)
    }, [globalStore.modals])

    const header = useMemo(
        () => ({
            header: t`navigation-items-view.header.navigation-items`,
            breadcrumbs: [t`navigation-items-view.header.navigation-items`],
            createOptions: {
                onClick: handleNewNavigationItemClick,
                item: t`navigation-items-view.header.navigation-items`,
            },
        }),
        [handleNewNavigationItemClick],
    )
    const handleSegmentChange = useCallback(
        (segments: number[]) => store.loadSegments(segments),
        [store],
    )

    const filter = useMemo(
        (): IPageFilterProps => {
            return {
                actions: (
                    <Stack direction="row" spacing={1}>
                        <LocationFilterSelector />
                        <SegmentPickerButton
                            value={store.segments}
                            onChange={handleSegmentChange}
                        />
                    </Stack>
                ),
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [store.locationFilterSelectedValue, store.segments],
    )

    useEffect(() => {
        ;(async () => await store.init(globalStore.session.accessGroupId))()
    }, [store, globalStore.session.accessGroupId])

    const openDetailModalHandler = useCallback(
        (item) => {
            trackModuleEvent("Navigation Items | Detailed View", {
                [MixpanelProperties.ItemID]: item.id,
                [MixpanelProperties.AccessGroupName]:
                    globalStore.session.accessGroup?.name,
                [MixpanelProperties.AccessGroupID]:
                    globalStore.session.accessGroup?.id,
            })
            navigate(`/user-interface/content-items/${item.id}`, {
                state: { accessType: item?.row?.access_type },
            })
        },
        [
            globalStore.session.accessGroup?.id,
            globalStore.session.accessGroup?.name,
            navigate,
        ],
    )

    const handleFilterChange = useCallback(
        async (model: FilterModel) => {
            const sort = getSortModelForRepository(
                repository,
                globalStore.session.dataGridSortModel,
            )

            const modifiedModel: FilterModel = {
                ...model,
                items: model.items.map((item) => ({
                    ...item,
                    field: item.field === "tag_names" ? "tag_ids" : item.field,
                })),
            }

            await store.query({ sort, filter: modifiedModel })
        },
        [store, globalStore.session.dataGridSortModel],
    )

    const handleSortChange = useCallback(
        async (model: SortModel) => {
            const filter = getFilterModelForRepository(
                repository,
                globalStore.session.dataGridFilterModel,
            )

            await store.query({ filter, sort: model })
        },
        [store, globalStore.session.dataGridFilterModel],
    )

    const handleDeleteItem = useCallback(
        (id: number) => {
            globalStore.modals.open(
                () => (
                    <ConfirmModal
                        onConfirm={async (confirmed) => {
                            if (confirmed) {
                                await store.deleteItem(id)
                            }
                        }}
                        title={t`navigation-items-view.delete.are-you-sure`}
                        content={t`navigation-items-view.delete.do-you-want-delete`}
                    />
                ),
                {
                    variant: "slide-up-w600",
                },
            )
        },
        [globalStore.modals, store],
    )

    return (
        <ListPage
            header={header}
            filter={filter}
            loading={!store.contentItems.meta.initialized}
        >
            <DataGridProTable
                paginator={store.contentItems}
                data={store.contentItems.items}
                columns={columns}
                advancedOperations={{
                    pagination: "server",
                    filtering: "server",
                    sorting: "server",
                }}
                rowActionsRenderer={(item) => [
                    {
                        text: t`navigation-items-view.action.delete-item`,
                        onClick: () => handleDeleteItem(item?.id),
                    },
                ]}
                onRowClickEvent={openDetailModalHandler}
                repository={repository}
                loading={!store.contentItems.meta.initialized}
                onFilterChange={handleFilterChange}
                onSortChange={handleSortChange}
            />
        </ListPage>
    )
})

export const ContentItemsView = () => (
    <StoreProvider Store={ContentItemsStore}>
        <View />
    </StoreProvider>
)
