import { t } from "@lingui/macro"
import { Autocomplete, TextField } from "@mui/material"

import { IAutoCompleteTextFieldProps } from "src/types/auto-complete-text-field"

export const AutoCompleteTextField = <T extends { name: string; id: number }>(
    props: IAutoCompleteTextFieldProps<T>,
) => {
    const handleChange = (
        _: React.SyntheticEvent<Element, Event>,
        value: T[],
    ) => {
        props.onChange(value)
    }

    return (
        <Autocomplete
            multiple
            data-testid="autocomplete-text-field"
            options={props.options}
            getOptionLabel={(option: T) => option.name}
            value={props.defaultValues}
            onChange={handleChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    placeholder={props.placeholder}
                />
            )}
            loading={props.loading}
            loadingText={t`tags-component.loading`}
        />
    )
}
