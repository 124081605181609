import { configureScope } from "@sentry/browser"
import camelcaseKeys from "camelcase-keys"
import { IReactionDisposer, makeAutoObservable } from "mobx"

import { mixpanelIdentify, mixpanelSet } from "src/analytics/AnalyticsManager"
import {
    AdminService,
    OpenAPI,
    avy_api_pkg_admin_domain_UserDetails,
    avy_api_pkg_segment_SegmentForSearch,
} from "src/api"

import { CustomSegmentsAdminService } from "src/api/_custom/services/SegmentsAdminService"

import { createLoadingKeys } from "src/lib/loading"

import { Channel } from "src/channel"
import { loads } from "src/channel/utils"
import { DEFAULT_ACCESS_GROUP, environment } from "src/config"
import { LocalStorage } from "src/lib/local-storage"
import { reportUnhandledApiError } from "src/lib/report"
import { DEFAULT_LANGUAGE, i18n } from "src/locales"
import { Locale, Locales } from "src/locales/locale"
import {
    ISelectedAccessGroup,
    ISessionLocalStorage,
    IUser,
} from "src/types/local-storage"

import { IFilterModel, ISortModel } from "src/types/data-grid-pro"
import { removeAccessGroup } from "src/api/_indexedDB/queries/Segments"
import { INavigationMenuData } from "src/analytics/types/navigationMenu"
import {
    INavigationItemGroup,
    INavigationItemHighGroup,
    INavigationItemSingle,
} from "src/store/navigation/types"
import { removeForwardSlash } from "src/lib/strings"

export interface ISessionLegalEntity {
    id: number
    legalEntityId?: number
    legalName?: string
    orgId?: string
    propertyOwnerId?: number
}

export interface ISessionPropertyOwner {
    id: number
    propertyOwnerId?: number
    legalName?: string
    legalEntities?: ISessionLegalEntity[]
}

/**
 * SessionStore keeps track of everything relevant to the current session. This
 * includes available access groups, bearer token, selected language etc. The
 * store acts on external libraries to do this, like i18n and OpenAPI.
 */
export class SessionStore implements IDisposable {
    static LoadingKeys = createLoadingKeys(
        "init",
        "reload-access-group-segments",
    )

    nextAccessGroup: ISelectedAccessGroup | null = null
    accessGroups: ISelectedAccessGroup[] = []
    segments: ISegmentForSearch[] = []
    get segmentIds() {
        return this.segments.map((x) => x.id)
    }
    mainPropertyName: string | null = null
    propertyOwners: ISessionPropertyOwner[] = []
    propertyOwnerId?: number
    private _dataGridSortModel?: ISortModel
    private _dataGridFilterModel?: IFilterModel

    private adminId = -1

    private _navigationDataForTracking: INavigationMenuData = {
        none: [],
        residents: [],
        communication: [],
        management: [],
        marketplace: [],
        "users-&-access": [],
        configuration: [],
    }

    private localStorage = new LocalStorage<ISessionLocalStorage>({
        SelectedAccessGroup: null,
        i18nextLng: null,
    })

    private bearerTokenReactDisposer?: IReactionDisposer
    private unauthorizedErrorListenerDisposer?: () => void
    private _initialized = false

    constructor() {
        makeAutoObservable(this)

        this.initLanguage()
        this.listenToLanguageChanges()

        this.initBearerToken()

        this.listenToUnauthorizedRequestErrors()
    }

    dispose() {
        this.bearerTokenReactDisposer?.()
        i18n.removeListener("change", this.updateLanguange)
        this.unauthorizedErrorListenerDisposer?.()
    }

    private get stored() {
        return this.localStorage.data
    }

    get accessGroupId() {
        return this.accessGroup.id
    }

    get accessGroup() {
        // If the access group in local storage is null or if it could be parsed
        // as json we return the default access group.
        if (this.stored.SelectedAccessGroup == null) {
            return DEFAULT_ACCESS_GROUP
        }
        if (
            typeof this.stored.SelectedAccessGroup === "string" &&
            this.stored.SelectedAccessGroup === ""
        ) {
            return DEFAULT_ACCESS_GROUP
        }
        if (typeof this.stored.SelectedAccessGroup === "string") {
            return JSON.parse(this.stored.SelectedAccessGroup)
        }
        return this.stored.SelectedAccessGroup
    }

    get user() {
        const raw =
            sessionStorage.getItem("user") ?? localStorage.getItem("user")

        if (raw == null) {
            return null
        }
        try {
            const user = JSON.parse(raw) as IUser

            return { ...user, adminId: this.adminId }
        } catch {
            // If the user is not parsable it means that the value is invalid
            // somehow. If it's invalid we return null and let the consumer handle
            // that case.
            return null
        }
    }

    get userTokenIsPresent() {
        const isUserTokenPresent = !!(
            this.user !== null && this.user.accessToken !== undefined
        )
        return isUserTokenPresent
    }

    get lastLoggedInUser() {
        return this.stored.lastLoggedInUser
    }

    get language() {
        if (this.stored.i18nextLng != null) {
            if (Object.values(Locale).includes(this.stored.i18nextLng)) {
                return this.stored.i18nextLng
            }
        }
        return DEFAULT_LANGUAGE
    }

    get hasSelectedAllAccessGroups() {
        return this.accessGroupId === DEFAULT_ACCESS_GROUP.id
    }

    get initialized() {
        return this._initialized
    }

    get dataGridSortModel() {
        return this.stored.dataGridSortModel
    }

    get dataGridFilterModel() {
        return this.stored.dataGridFilterModel
    }

    get selectedParakeyType() {
        return this.stored.selectedParakeyType
    }

    get navigationDataForTracking() {
        return this.stored.navigationDataForTracking
    }

    setDataGridSortModel(model: ISortModel) {
        this._dataGridSortModel = { ...this._dataGridSortModel, ...model }
        this.localStorage.set("dataGridSortModel", this._dataGridSortModel)
    }

    setDataGridFilterModel(filters: IFilterModel) {
        this._dataGridFilterModel = { ...this._dataGridFilterModel, ...filters }
        this.localStorage.set("dataGridFilterModel", this._dataGridFilterModel)
    }

    setSelectedParakeyType(type: string) {
        this.localStorage.set("selectedParakeyType", type)
    }
    async setSelectedAccessGroup(value: ISelectedAccessGroup) {
        await this.loadAccessGroup(value)
    }

    hasAccessToModule(module: Modules | undefined) {
        if (this.user != null) {
            if (module === undefined || this.user.isSuper) {
                return this.user.isSuper
            }
            return this.user.config[module]
        }
        return false
    }

    destroy() {
        localStorage.removeItem("user")
        localStorage.removeItem("navBarClosedSections")
        sessionStorage.removeItem("user")
        removeAccessGroup("-1")
            .then(() => null)
            .catch((e) => e)
    }

    @loads(() => SessionStore.LoadingKeys.init)
    async init() {
        try {
            let userDetails: avy_api_pkg_admin_domain_UserDetails = {}

            if (this.user?.raw !== undefined) {
                userDetails = this.user?.raw
            } else {
                userDetails = await AdminService.getV1AdminUserDetails()
            }

            const accessGroups = userDetails.access_groups ?? []
            this.setAccessGroups(accessGroups as ISelectedAccessGroup[])

            if (userDetails.property_owner_id != null) {
                this.setProperOwnerId(userDetails.property_owner_id)
            }

            this.setAdminId(Number(userDetails.admin_id))

            if (
                userDetails.property_owners != null &&
                userDetails.property_owners.length > 0
            ) {
                this.setProperOwners(
                    camelcaseKeys(
                        userDetails.property_owners.map((propertyOwner) => ({
                            ...propertyOwner,
                            id: propertyOwner.property_owner_id as number,
                            legalEntities: propertyOwner.legal_entities?.map(
                                (legalEntity) => ({
                                    ...legalEntity,
                                    id: legalEntity.legal_entity_id as number,
                                }),
                            ),
                        })),
                        { deep: true },
                    ) as ISessionPropertyOwner[],
                )

                if (userDetails.property_owners[0].legal_name != null) {
                    this.setMainPropertyName(
                        userDetails.property_owners[0].legal_name,
                    )
                }
                const legalEntityName =
                    userDetails.property_owners[0].legal_entities
                if (legalEntityName != null && legalEntityName.length > 0) {
                    if (environment.MIXPANEL_TOKEN !== "") {
                        mixpanelIdentify(userDetails.admin_id?.toString())
                        mixpanelSet({
                            "Property Owner Name ":
                                this.propertyOwners[0].legalName ?? "",
                            "Legal Entity Name":
                                legalEntityName[0].legal_name ?? "",
                        })
                    }
                }
            }

            await this.loadAccessGroup(this.accessGroup)
            if (
                Number(this.localStorage.data.lastLoggedInUser) !== this.adminId
            ) {
                this.localStorage.remove("SelectedAccessGroup")
                this.localStorage.set("lastLoggedInUser", String(this.adminId))
                this.localStorage.remove("dataGridSortModel")
                this.localStorage.remove("dataGridFilterModel")
            }
            this.setInitialized()
            this.setSentryScope(
                String(userDetails.admin_id ?? null),
                Boolean(userDetails.is_super),
            )
            return true
        } catch (e) {
            reportUnhandledApiError(e)
            return false
        }
    }

    private setSentryScope(id: string, isSuper: boolean) {
        configureScope((scope) => {
            scope.setUser({ id, isSuper })
        })
    }

    private initLanguage() {
        i18n.activate(this.language)
    }

    private listenToLanguageChanges() {
        i18n.on("change", this.updateLanguange)
    }

    private initBearerToken() {
        this.updateBearerToken()
    }

    private listenToUnauthorizedRequestErrors() {
        this.unauthorizedErrorListenerDisposer = Channel.addListener(
            (event) => {
                if (event.name === "request/completed") {
                    if (event.payload.status === 401) {
                        const loginPathname = "/login"
                        if (!window.location.pathname.includes(loginPathname)) {
                            window.location.href = loginPathname
                        }
                    }
                }
            },
        )
    }

    private setLanguage(value: Locale) {
        this.localStorage.set("i18nextLng", value)
    }

    private updateBearerToken = () => {
        const tokenWithoutBearer = this.getBearerToken()
        if (tokenWithoutBearer != null) {
            OpenAPI.TOKEN = tokenWithoutBearer
        }
    }

    private updateLanguange = () => {
        const valid = Object.keys(Locales)
        if (valid.includes(i18n.locale)) {
            this.setLanguage(i18n.locale as Locale)
        }
    }

    @loads(() => SessionStore.LoadingKeys["reload-access-group-segments"])
    private async loadAccessGroup(accessGroup: ISelectedAccessGroup) {
        try {
            this.setNextAccessGroup(accessGroup)
            const rawSegments =
                await CustomSegmentsAdminService.getV1AdminSegmentSearch({
                    accessGroupId:
                        accessGroup.id !== DEFAULT_ACCESS_GROUP.id
                            ? String(accessGroup.id)
                            : undefined,
                })
            const segments = (
                rawSegments as NonNullableMap<avy_api_pkg_segment_SegmentForSearch>[]
            ).map((segment) => ({
                ...segment,
                tenantCount: segment.tenant_count,
                type: segment.type as ISegmentForSearch["type"],
            }))
            this.setAccessGroup(accessGroup, segments)
        } catch (e) {
            reportUnhandledApiError(e)
        } finally {
            this.clearNextAccessGroup()
        }
    }

    private setSegments(segments: ISegmentForSearch[]) {
        this.segments = segments
    }

    private setAccessGroups(accessGroups: ISelectedAccessGroup[]) {
        this.accessGroups = accessGroups
    }

    private setNextAccessGroup(accessGroup: ISelectedAccessGroup) {
        this.nextAccessGroup = accessGroup
    }

    private setAdminId(userId: number) {
        this.adminId = userId
    }

    private clearNextAccessGroup() {
        this.nextAccessGroup = null
    }

    private setAccessGroup(
        accessGroup: ISelectedAccessGroup,
        segments: ISegmentForSearch[],
    ) {
        this.localStorage.set("SelectedAccessGroup", accessGroup)
        this.segments = segments
    }

    private setMainPropertyName(mainPropertyName: string) {
        this.mainPropertyName = mainPropertyName
    }

    private setProperOwners(propertyOwners: ISessionPropertyOwner[]) {
        this.propertyOwners = propertyOwners
    }

    private setProperOwnerId(propertyOwnerId: number) {
        this.propertyOwnerId = propertyOwnerId
    }

    private setInitialized() {
        this._initialized = true
    }

    private getBearerToken() {
        return this.user?.accessToken.replace("Bearer ", "") ?? null
    }

    public setUser = (user: {}, storage: Storage) => {
        storage.setItem("user", JSON.stringify(user))
        this.updateBearerToken()
    }

    public setNavigationDataForTracking = (
        menuItems: INavigationItemHighGroup[],
    ) => {
        menuItems.forEach((item) => {
            const groupItemData = item.children.map((child) => {
                if (child.hasOwnProperty("to")) {
                    const _child = child as INavigationItemSingle
                    return removeForwardSlash(_child.to as string)
                } else {
                    const _child = child as INavigationItemGroup
                    return removeForwardSlash(_child.prefix)
                }
            })

            this._navigationDataForTracking = {
                ...this._navigationDataForTracking,
                [item.group]: groupItemData,
            }
        })

        this.localStorage.set(
            "navigationDataForTracking",
            this._navigationDataForTracking,
        )
    }
}
