import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"
import React from "react"

import {
    IFeatureLists,
    INavigationItemsPropsProps,
    INavigationItemsValidationProps,
} from "./types/navigationItemsProps"

import { FormFields } from "src/lib/form-fields"
import { createLoadingKeys } from "src/lib/loading"
import { loads } from "src/channel/utils"
import {
    EmbedAdminService,
    ConnectorAdminService,
    ContentAdminService,
    content_ContentItemEntity,
    content_ContentItemLocation,
    content_UpdateContentItemRequest,
} from "src/api"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { MixpanelProperties } from "src/analytics/constants/properties"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"

import { Channel } from "src/channel"
import { getFeatureDisplayDetails } from "src/lib/feature-tag-description"
import { reportError } from "src/lib/report"

export class NavigationItemDetailStore {
    static Context = React.createContext<NavigationItemDetailStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "submit")

    hasError: boolean = false
    isLoading = false

    private id: number | null = null
    private _featureTargetLists: IFeatureLists[] = []
    private _segmentIds: Array<number> = []
    private _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    private _accessType: string = "WRITE"

    form = new FormFields<INavigationItemsPropsProps>({
        accessGroupID: DEFAULT_ACCESS_GROUP.id,
        entity: undefined,
        entityID: 0,
        enHeaderMainNavigation: "",
        svHeaderMainNavigation: "",
        fiHeaderMainNavigation: "",
        enHeaderOverview: "",
        svHeaderOverview: "",
        fiHeaderOverview: "",
        iconUrl: "",
        imageUrl: "",
        internalName: "",
        priorityMainNavigation: 0,
        priorityOverview: 0,
        showInMainNavigation: false,
        showInOverview: false,
        enSubHeader: "",
        svSubHeader: "",
        fiSubHeader: "",
        segmentIds: [],
    })

    constructor() {
        makeAutoObservable(this)
    }

    //#region getters
    get segmentIDs() {
        return this._segmentIds
    }

    get featureTargetLists() {
        return this._featureTargetLists
    }

    get accessType() {
        return this._accessType
    }

    get isReadOnly() {
        return this.accessType === "READ"
    }
    get accessGroupId() {
        return this._accessGroupId
    }

    get isEditMode() {
        return this.id !== null
    }

    //#endregion

    //#region setters
    setAccessGroupId(id: number) {
        this._accessGroupId = id
    }

    setSegmentIDs(segmentIDs: number[]) {
        this.form.set("segmentIds", segmentIDs)
        this._segmentIds = segmentIDs
    }

    setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading)

    private setId(id: number) {
        this.id = id
    }

    private setFeatureTargetLists(featureTargetLists: IFeatureLists[]) {
        this._featureTargetLists = featureTargetLists
    }

    private setAccessType(accessType: string) {
        this._accessType = accessType
    }
    //#endregion

    //#region operations
    @loads(() => NavigationItemDetailStore.LoadingKeys.init)
    async init(
        id: number | undefined,
        accessGroupId: number,
        accessType: string | undefined,
    ) {
        try {
            this.setAccessGroupId(accessGroupId)

            if (id != null) {
                this.setId(id)

                if (accessType !== undefined) {
                    this.setAccessType(accessType)
                }

                const response =
                    await ContentAdminService.getV1AdminContentItem({
                        contentItemId: id,
                    })
                const responseData = response?.config?.navigation_config

                this.form.init({
                    accessGroupID:
                        response?.access_group_id ?? DEFAULT_ACCESS_GROUP.id,
                    entity: response?.entity ?? undefined,
                    entityID: response?.entity_id ?? -1,
                    enHeaderMainNavigation:
                        responseData?.header_main_navigation !== undefined
                            ? responseData?.header_main_navigation["en"] ?? ""
                            : "",
                    svHeaderMainNavigation:
                        responseData?.header_main_navigation !== undefined
                            ? responseData?.header_main_navigation["sv"] ?? ""
                            : "",
                    fiHeaderMainNavigation:
                        responseData?.header_main_navigation !== undefined
                            ? responseData?.header_main_navigation["fi"] ?? ""
                            : "",
                    enHeaderOverview:
                        responseData?.header_overview !== undefined
                            ? responseData?.header_overview["en"] ?? ""
                            : "",
                    svHeaderOverview:
                        responseData?.header_overview !== undefined
                            ? responseData?.header_overview["sv"] ?? ""
                            : "",
                    fiHeaderOverview:
                        responseData?.header_overview !== undefined
                            ? responseData?.header_overview["fi"] ?? ""
                            : "",
                    iconUrl: responseData?.icon_url ?? "",
                    imageUrl: responseData?.image_url ?? "",
                    internalName: response?.internal_name ?? "",
                    priorityMainNavigation:
                        responseData?.priority_main_navigation ?? 0,
                    priorityOverview: responseData?.priority_overview ?? 0,
                    showInMainNavigation:
                        response?.location?.includes("navigation") ?? false,
                    showInOverview:
                        response?.location?.includes("overview") ?? false,
                    enSubHeader:
                        responseData?.subheader !== undefined
                            ? responseData?.subheader["en"] ?? ""
                            : "",
                    svSubHeader:
                        responseData?.subheader !== undefined
                            ? responseData?.subheader["sv"] ?? ""
                            : "",
                    fiSubHeader:
                        responseData?.subheader !== undefined
                            ? responseData?.subheader["fi"] ?? ""
                            : "",
                    segmentIds: response?.segment_ids ?? [],
                })

                if (
                    response?.segment_ids !== undefined ||
                    response?.segment_ids !== null
                ) {
                    this.setSegmentIDs(response?.segment_ids ?? [])
                }

                this.setAccessGroupId(
                    response?.access_group_id ?? DEFAULT_ACCESS_GROUP.id,
                )
            }
            await this.featureTargetList(accessGroupId)
        } catch (error) {
            reportError(t`edit-information-item-modal.load-data-fail`, error)
            this.setAccessType("READ")
        }

        return Promise.resolve()
    }

    @loads(() => NavigationItemDetailStore.LoadingKeys.submit)
    async submit() {
        const { id } = this
        try {
            await this.form.catchErrors(async () => {
                const { data } = this.form

                const isOverviewOrIntegrationConnector =
                    data.showInOverview ||
                    data.entity === "integration_connector"

                const request: content_UpdateContentItemRequest = {
                    internal_name: data.internalName,
                    entity: data?.entity ?? undefined,
                    entity_id: data.entityID ?? "",
                    location: this.getLocationArray(),
                    config: {
                        navigation_config: {
                            header_main_navigation: {
                                en: data.showInMainNavigation
                                    ? data.enHeaderMainNavigation
                                    : "",
                                sv: data.showInMainNavigation
                                    ? data.svHeaderMainNavigation
                                    : "",
                                fi: data.showInMainNavigation
                                    ? data.fiHeaderMainNavigation
                                    : "",
                            },
                            header_overview: {
                                en: data.showInOverview
                                    ? data.enHeaderOverview
                                    : "",
                                sv: isOverviewOrIntegrationConnector
                                    ? data.svHeaderOverview
                                    : "",
                                fi: data.showInOverview
                                    ? data.fiHeaderOverview
                                    : "",
                            },
                            icon_url: data.iconUrl,
                            image_url: data.imageUrl,

                            priority_main_navigation: data.showInMainNavigation
                                ? Number(data.priorityMainNavigation)
                                : 0,
                            priority_overview: isOverviewOrIntegrationConnector
                                ? Number(data.priorityOverview)
                                : 0,
                            subheader: {
                                en: isOverviewOrIntegrationConnector
                                    ? data.enSubHeader
                                    : "",
                                sv: isOverviewOrIntegrationConnector
                                    ? data.svSubHeader
                                    : "",
                                fi: data.showInOverview ? data.fiSubHeader : "",
                            },
                        },
                    },
                }

                if (id !== null) {
                    await ContentAdminService.putV1AdminContentItem({
                        contentItemId: id,
                        request,
                    })

                    await ContentAdminService.putV1AdminContentItemPublish({
                        contentItemId: id,
                        request: {
                            published_in: this._segmentIds,
                        },
                    })

                    Channel.send({
                        name: "repository/updated",
                        payload: {
                            repository: "navigation-items",
                            action: "update",
                        },
                    })
                } else {
                    const response =
                        await ContentAdminService.postV1AdminContentItem({
                            request: {
                                access_group_id: this.accessGroupId,
                                entity: data?.entity as content_ContentItemEntity,
                                entity_id: data.entityID,
                                internal_name: data.internalName,
                                location: this.getLocationArray(),
                                config: {
                                    navigation_config: {
                                        header_main_navigation: {
                                            en: data.showInMainNavigation
                                                ? data.enHeaderMainNavigation
                                                : "",
                                            sv: data.showInMainNavigation
                                                ? data.svHeaderMainNavigation
                                                : "",
                                            fi: data.showInMainNavigation
                                                ? data.fiHeaderMainNavigation
                                                : "",
                                        },
                                        header_overview: {
                                            en: data.showInOverview
                                                ? data.enHeaderOverview
                                                : "",
                                            sv: data.showInOverview
                                                ? data.svHeaderOverview
                                                : "",
                                            fi: data.showInOverview
                                                ? data.fiHeaderOverview
                                                : "",
                                        },
                                        icon_url: data.iconUrl,
                                        image_url: data.imageUrl,

                                        priority_main_navigation:
                                            data.showInMainNavigation
                                                ? Number(
                                                      data.priorityMainNavigation,
                                                  )
                                                : 0,
                                        priority_overview: data.showInOverview
                                            ? Number(data.priorityOverview)
                                            : 0,
                                        subheader: {
                                            en: data.showInOverview
                                                ? data.enSubHeader
                                                : "",
                                            sv: data.showInOverview
                                                ? data.svSubHeader
                                                : "",
                                            fi: data.showInOverview
                                                ? data.fiSubHeader
                                                : "",
                                        },
                                    },
                                },
                            },
                        })

                    if (
                        response.content_item_id !== null ||
                        response.content_item_id !== undefined
                    ) {
                        await ContentAdminService.putV1AdminContentItemPublish({
                            contentItemId: response.content_item_id ?? 0,
                            request: {
                                published_in: this._segmentIds,
                            },
                        })
                    }

                    Channel.send({
                        name: "repository/updated",
                        payload: {
                            repository: "navigation-items",
                            action: "create",
                        },
                    })
                }
            })
        } catch (error) {
            reportError(t`edit-information-item-modal.save-item-fail`, error)
        } finally {
            this.setIsLoading(false)
        }
    }

    getLocationArray() {
        const location: content_ContentItemLocation[] = []

        if (this.form.get("showInMainNavigation")) {
            location.push("navigation")
        }
        if (this.form.get("showInOverview")) {
            location.push("overview")
        }

        return location
    }

    handleSubmit = async (accessGroupName: string, accessGroupID: number) => {
        const navigationValidation: {
            field: keyof INavigationItemsValidationProps
        }[] = this.form.get("showInMainNavigation")
            ? [
                  { field: "priorityMainNavigation" },
                  { field: "svHeaderMainNavigation" },
                  { field: "enHeaderMainNavigation" },
              ]
            : []
        const overviewValidation: {
            field: keyof INavigationItemsValidationProps
        }[] =
            this.form.get("showInOverview") &&
            this.form.get("entity") !== "integration_connector"
                ? [
                      { field: "priorityOverview" },
                      { field: "svHeaderOverview" },
                      { field: "enHeaderOverview" },
                  ]
                : []

        this.form.validateRequiredFields([
            { field: "internalName" },
            { field: "entity" },
            { field: "entityID", validate: (val) => val !== 0 },
            { field: "iconUrl" },
            ...navigationValidation,
            ...overviewValidation,
        ])

        if (this.form.hasErrors() === true) {
            this.setIsLoading(false)
            return false
        }

        trackModuleEvent("Navigation Items | Save", {
            [MixpanelProperties.ItemName]: this.form.data.internalName,
            [MixpanelProperties.AccessGroupName]: accessGroupName,
            [MixpanelProperties.AccessGroupID]: accessGroupID,
        })
        await this.submit()
        return true
    }

    async getAdminNavigationModule(): Promise<IFeatureLists[]> {
        const responseData =
            await ContentAdminService.getV1AdminContentNavigationModule()
        return (
            responseData?.map((data) => {
                return {
                    id: data.feature_flag_id ?? 0,
                    label:
                        data?.module_name !== null
                            ? getFeatureDisplayDetails({
                                  name: data?.module_name,
                              })?.displayName ?? ""
                            : "",
                }
            }) ?? []
        )
    }

    async getAdminEmbedIntegrationList(
        accessGroupId?: number,
    ): Promise<IFeatureLists[]> {
        let responseData = []
        const response =
            await EmbedAdminService.postV1AdminEmbedIntegrationList({
                request: {
                    access_group_id:
                        this.form.get("accessGroupID") > -1
                            ? this.form.get("accessGroupID")
                            : accessGroupId ?? DEFAULT_ACCESS_GROUP.id,
                    page_number: 1,
                    page_size: 1000,
                },
            })
        responseData = response?.integrations ?? []
        return (
            responseData?.map((data) => {
                return { id: data.id ?? 0, label: data?.name ?? "" }
            }) ?? []
        )
    }

    async getConnectorIntegrationList(): Promise<IFeatureLists[]> {
        let responseData = []
        const response =
            await ConnectorAdminService.getV1AdminConnectorIntegration({
                type: "mkb_sensor",
            })
        responseData = response ?? []
        return (
            responseData?.map((data) => {
                return { id: data.integration_id ?? 0, label: data?.name ?? "" }
            }) ?? []
        )
    }

    async featureTargetList(accessGroupId?: number) {
        try {
            let featureLists: IFeatureLists[] = []
            this.setFeatureTargetLists([])
            switch (this.form.get("entity")) {
                case "module":
                    featureLists = await this.getAdminNavigationModule()
                    break
                case "embed":
                    featureLists = await this.getAdminEmbedIntegrationList(
                        accessGroupId,
                    )
                    break
                case "integration_connector":
                    featureLists = await this.getConnectorIntegrationList()
                    break
                default:
                    break
            }
            this.setFeatureTargetLists(featureLists)
        } catch (error) {}
    }
    //#endregion
}
