import React from "react"
import { makeAutoObservable } from "mobx"

import { content_ContentItemLocation, ContentAdminService } from "src/api"
import { createLoadingKeys } from "src/lib/loading"
import { Channel } from "src/channel"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { Pagination } from "src/lib/pagination"
import { loads } from "src/channel/utils"
import { IContentItems, ILocationType } from "src/views/content-items/types"
import { IAdvanceQueryModel } from "src/types/data-grid-pro"
import { CustomDataHelper } from "src/api/_custom/services/DataHelper"
import { locationSwitch } from "src/views/content-items/helper/locationToPrioritySwtich"
import { locationItemsOptionList } from "src/views/content-items/constants/locationItemsOptionList"

export class ContentItemsStore implements IDisposable {
    static Context = React.createContext<ContentItemsStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "loading", "getAssignees")

    _locationFilterSelected: string = Object.keys(
        locationItemsOptionList,
    )[0].toString()
    _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    _segments: number[] = []
    _priorityColumnName: string = "all"

    private repositoryUpdatesListenerDisposer?: () => void

    constructor() {
        makeAutoObservable(this)
    }

    dispose(): void {
        this.repositoryUpdatesListenerDisposer?.()
    }

    contentItems = new Pagination(
        async (query) => {
            const response =
                await ContentAdminService.postV1AdminContentItemList({
                    request: {
                        access_group_id: this._accessGroupId,
                        page_number: query.page,
                        page_size: query.pageSize,
                        filter: query.advanceQuery,
                        location:
                            this._locationFilterSelected === "all"
                                ? undefined
                                : ([
                                      this._locationFilterSelected,
                                  ] as content_ContentItemLocation[]),
                        ...CustomDataHelper.PrepareVisibilityData(this),
                    },
                })
            const items: IContentItems[] =
                response?.content_items?.map((item) => ({
                    id: item.content_item_id ?? 0,
                    internal_name: item.internal_name ?? "",
                    entity: item.entity ?? "",
                    location: item.location,
                    priority_main_navigation: item.priority_main_navigation,
                    priority_move_in_checklist: item.priority_move_in_checklist,
                    priority_overview: item.priority_overview,
                    priority_spotlight: item.priority_spotlight,
                    priority_wizard: item.priority_wizard,
                    published_to:
                        item.segment_ids?.length != null
                            ? `${item.segment_ids?.length} segments`
                            : "",
                    access_type: item?.access_type ?? "WRITE",
                })) ?? []

            return {
                items,
                count: response.total_count ?? 0,
            }
        },
        { loadingKey: ContentItemsStore.LoadingKeys.loading },
    )

    setLocationsFilterSelected = (value: string) => {
        this._locationFilterSelected = value
        this.priorityColumnSelector(value as ILocationType)
    }

    refresh = async () => {
        await this.contentItems.loadInitialPage()
    }

    get segments() {
        return this._segments
    }

    get locationFilterSelectedValue() {
        return this._locationFilterSelected
    }

    get priorityColumnName() {
        return this._priorityColumnName
    }

    private setSegments(segments: number[]) {
        this._segments = segments
    }

    async loadSegments(segments: number[]) {
        this.setSegments(segments)
        await this.contentItems.loadInitialPage()
    }

    private setAccessGroupId(accessGroupId: number) {
        this._accessGroupId = accessGroupId
    }

    private priorityColumnSelector = (location: ILocationType) => {
        this._priorityColumnName = locationSwitch(location)
    }

    @loads(() => ContentItemsStore.LoadingKeys.init)
    async init(accessGroupId: number, advanceQuery?: IAdvanceQueryModel) {
        this.listenToMessageRepositoryUpdated()
        this.setAccessGroupId(accessGroupId)
        await this.contentItems.loadInitialPage(advanceQuery)
    }

    async query(advanceQuery: IAdvanceQueryModel) {
        await this.contentItems.loadAdvanceQuery(advanceQuery)
    }

    private listenToMessageRepositoryUpdated() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "content-items"
                ) {
                    await this.contentItems.reload()
                }
            },
        )
    }

    deleteItem = async (id: number) => {
        try {
            await ContentAdminService.deleteV1AdminContentItem({
                contentItemId: id,
            })
        } catch (error) {
            reportError("Failed to delete item")
        } finally {
            await this.contentItems.reload()
        }
    }
}
