import { Typography, useTheme } from "@mui/material"
import { memo } from "react"

import { StyledTooltip } from "src/components/Tooltips/TooltipWithIcon/styled"
import { Icon } from "src/components/icons"
import { Alert16 } from "src/components/icons/Alert16"
import { ITooltipWithIconProps } from "src/types/tooltip/tooltipWithIconProps"

export const TooltipWithIcon = memo((props: ITooltipWithIconProps) => {
    const theme = useTheme()

    return (
        <StyledTooltip title={props.tooltipText} arrow>
            <Typography sx={{ marginTop: "16px" }}>
                <Icon
                    icon={<Alert16 width="20px" height="20px" />}
                    color={theme.palette.grey[700]}
                />
            </Typography>
        </StyledTooltip>
    )
})
