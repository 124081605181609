import { t } from "@lingui/macro"
import {
    IconButton,
    ListItem as MUIListItem,
    ListItemText as MUIListItemText,
    Stack,
    styled,
    Typography,
    useTheme,
} from "@mui/material"
import { observer } from "mobx-react"

import { HighlightedQuery } from "src/components/HighlightedQuery"
import { Icon } from "src/components/icons"
import { Cross16 } from "src/components/icons/Cross16"
import { User16 } from "src/components/icons/User16"
import { Tooltip } from "src/components/Tooltips/Tooltip"
import { formatInteger } from "src/lib/number"
import { getSegmentTypeIcon } from "src/lib/segment-type"
import { SegmentPickerStore } from "src/modals/segment-picker/store"
import { truncatePath } from "src/modals/segment-picker/utils"
import { useStore } from "src/store/lib/useStore"

const ListItemInner = styled(MUIListItem)(({ theme }) => ({
    background: theme.palette.grey[100],
    padding: "0 90px 0 12px",
    height: "50px",
    borderRadius: "1000px",
    marginBottom: "2px",
}))

const ListItemText = styled(MUIListItemText)({
    marginLeft: "8px",
})

const DeselectButton = styled(IconButton)(({ theme }) => ({
    background: theme.palette.grey[300],
    padding: "4px",
    "&:hover": {
        background: theme.palette.grey[500],
    },
}))

interface IProps {
    segment: ISegmentForSearch
}

export const ListItem = observer(({ segment }: IProps) => {
    const store = useStore(SegmentPickerStore)
    const theme = useTheme()

    const { icon, color } = getSegmentTypeIcon(segment.type)

    const truncatedSegmentPath = truncatePath(segment.path)

    return (
        <ListItemInner
            secondaryAction={
                <>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Stack
                            direction="row"
                            spacing={0.5}
                            alignItems="center"
                        >
                            <Icon
                                icon={<User16 />}
                                color={theme.palette.grey[900]}
                            />
                            <Typography
                                fontSize="12px"
                                color={theme.palette.grey[700]}
                            >
                                {formatInteger(segment.tenantCount)}
                            </Typography>
                        </Stack>
                        <DeselectButton
                            title={t({
                                id: `segment-picker.selected.deselect-segment`,
                                values: {
                                    name: segment.name,
                                },
                            })}
                            onClick={() => store.deselectSegment(segment)}
                        >
                            <Cross16 />
                        </DeselectButton>
                    </Stack>
                </>
            }
        >
            <Icon icon={icon} size={16} color={color} />
            <ListItemText
                primary={
                    <Typography
                        fontSize="14px"
                        fontWeight={500}
                        color={theme.palette.grey[900]}
                        lineHeight={1.4}
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        display="inherit"
                        title={segment.name}
                    >
                        <HighlightedQuery
                            text={segment.name}
                            query={store.selectedSegments.meta.search}
                        />
                    </Typography>
                }
                secondary={
                    <Tooltip
                        title={
                            truncatedSegmentPath !== segment.path
                                ? segment.path
                                : ""
                        }
                        arrow
                    >
                        <Typography
                            component="span"
                            fontSize="12px"
                            lineHeight={1.4}
                            color={theme.palette.grey[700]}
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            display="inherit"
                        >
                            <HighlightedQuery
                                text={truncatedSegmentPath}
                                query={store.selectedSegments.meta.search}
                            />
                        </Typography>
                    </Tooltip>
                }
            />
        </ListItemInner>
    )
})
