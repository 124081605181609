import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"
import moment from "moment"
import React from "react"

import {
    message_CreateMessageRequest,
    message_CreateMessageAttachment,
    message_MessageAttachment,
    message_UpdateMessage,
    MessageAdminService,
    PreviewService,
} from "src/api"
import { Channel } from "src/channel"
import { loads } from "src/channel/utils"
import { environment } from "src/config"
import { parseDate } from "src/lib/date"
import { persistFiles } from "src/lib/file"
import { FormFields } from "src/lib/form-fields"
import { createLoadingKeys } from "src/lib/loading"

interface IFormFields {
    admin_title: string
    title: string
    text: string
    access_type: string
    pinned: boolean
    send_push: boolean
    publish_at: Date | null
    unpublish_at: Date | null
    event_starts_at: Date | null
    event_ends_at: Date | null
    include_in_chatbot: boolean
    segment_ids: number[]
    imageAttachments: IFile[]
    documentAttachments: IFile[]
}

type IPersistedNoticeBoardPostFile = Omit<IPersistedFile, "type"> & {
    type: "image" | "doc"
}

const flutterBaseUrl = environment.FLUTTER_BASE_URL

export class NoticeBoardPostDetailStore {
    static Context = React.createContext<NoticeBoardPostDetailStore | null>(
        null,
    )
    static LoadingKeys = createLoadingKeys("init", "submit", "preview")
    fields = new FormFields<IFormFields>({
        admin_title: "",
        title: "",
        text: "",
        access_type: "",
        pinned: false,
        send_push: true,
        publish_at: null,
        unpublish_at: null,
        event_ends_at: null,
        event_starts_at: null,
        include_in_chatbot: false,
        segment_ids: [],
        imageAttachments: [],
        documentAttachments: [],
    })

    private id?: number

    constructor() {
        makeAutoObservable(this)
    }

    private _accessGroupId?: number

    get accessGroupId() {
        return this._accessGroupId
    }

    get isEditMode() {
        return this.id != null
    }

    setAccessGroupId(id?: number) {
        this._accessGroupId = id
    }

    @loads(() => NoticeBoardPostDetailStore.LoadingKeys.init)
    async init(accessGroupId: number, id?: number) {
        this.setId(id)

        if (id != null) {
            const response = await MessageAdminService.noticeBoardGetMessage({
                messageId: id,
            })
            this.setAccessGroupId(response.access_group_id ?? undefined)
            this.initFields({
                admin_title: response.admin_title ?? "",
                title: response.title ?? "",
                text: response.text ?? "",
                pinned: response.pinned ?? false,
                access_type: response.access_type ?? "",
                send_push: response.send_push ?? false,
                publish_at: parseDate(response.publish_at),
                unpublish_at: parseDate(response.unpublish_at),
                event_starts_at: parseDate(response.event_starts_at),
                event_ends_at: parseDate(response.event_ends_at),
                segment_ids: response.segment_ids ?? [],
                imageAttachments: this.toPersistedFiles(
                    response.attachments ?? [],
                    "image",
                ),
                documentAttachments: this.toPersistedFiles(
                    response.attachments ?? [],
                    "doc",
                ),
                include_in_chatbot: response.include_in_chatbot ?? false,
            })
        } else {
            this.setAccessGroupId(accessGroupId)
        }
    }

    getDetailsPreviewUrl = async () => {
        const detailsPreviewUrl =
            flutterBaseUrl + "notice-board-details-preview?base64="

        try {
            const url = await PreviewService.postV1PreviewMessage({
                request: {
                    attachments: await this.getAttachments(),
                    ...this.getRequestData(true),
                    segment_ids: this.fields.data.segment_ids ?? [],
                },
            })

            return `${detailsPreviewUrl}${encodeURIComponent(url)}`
        } catch (error) {
            return ""
        }
    }

    getListPreviewUrl = async () => {
        const listPreviewUrl = flutterBaseUrl + "notice-board-preview?base64="

        try {
            const url = await PreviewService.postV1PreviewMessageList({
                request: {
                    attachments: await this.getAttachments(),
                    ...this.getRequestData(true),
                    segment_ids: [],
                },
            })

            return `${listPreviewUrl}${encodeURIComponent(url)}`
        } catch (error) {
            return ""
        }
    }

    @loads(() => NoticeBoardPostDetailStore.LoadingKeys.submit)
    async submit(mode: string) {
        const { data } = this.fields

        if (this._accessGroupId == null) {
            this.fields.setErrors({ accessGroupId: t`errors.required` })
            return
        }

        await this.fields.catchErrors(async () => {
            if (this.id == null || mode === "Copy") {
                const request: message_CreateMessageRequest = {
                    attachments: await this.getAttachments(),
                    segment_ids: data.segment_ids ?? [],
                    ...this.getRequestData(),
                }

                const response =
                    await MessageAdminService.noticeBoardCreateMessage({
                        request,
                    })

                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "messages",
                        action: "create",
                        item: {
                            id: response.message_id ?? 0,
                            name: data.title,
                        },
                    },
                })
            } else {
                // TODO: remove validation check, when this is added in API
                // Update mode is missing required fields validation on API side. Only create mode has it.
                this.fields.validateRequiredFields([
                    { field: "title" },
                    { field: "admin_title" },
                ])
                if (this.fields.hasErrors()) return

                let updateRequest: message_UpdateMessage = {
                    attachments: await this.getAttachments(),
                    ...this.getRequestData(),
                }

                await MessageAdminService.noticeBoardUpdateMessage({
                    request: updateRequest,
                    messageId: this.id,
                })

                await MessageAdminService.noticeBoardPublishMessage({
                    messageId: this.id,
                    request: { published_in: data.segment_ids },
                })

                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "messages",
                        action: "update",
                        item: {
                            id: this.id,
                            name: data.title,
                        },
                    },
                })
            }
        })
    }

    formatDateTime = (date: Date | null): string | undefined => {
        return date !== null
            ? moment(date).format("YYYY-MM-DDTHH:mm:ss[Z]")
            : undefined
    }

    private getRequestData(isPreview: boolean = false) {
        const { data } = this.fields

        const formatDate = isPreview
            ? this.formatDateTime
            : (date: Date) => date?.toISOString()

        return {
            access_group_id: this._accessGroupId ?? 0,
            admin_title: data.admin_title.trim(),
            pinned: data.pinned,
            publish_at: data.publish_at?.toISOString(),
            send_push: data.send_push,
            text: data.text,
            title: data.title.trim(),
            unpublish_at: data.unpublish_at?.toISOString(),
            include_in_chatbot: data.include_in_chatbot,
            ...(data.event_starts_at !== null && {
                event_starts_at: formatDate(data.event_starts_at),
            }),
            ...(data.event_ends_at !== null && {
                event_ends_at: formatDate(data.event_ends_at),
            }),
        }
    }

    private getAttachments = async () => {
        const { data } = this.fields

        return this.toMessageAttachments(
            await this.persistAttachments(
                data.documentAttachments,
                data.imageAttachments,
            ),
        )
    }

    private setId(id?: number) {
        this.id = id
    }

    private initFields(fields: IFormFields) {
        this.fields.init(fields)
    }

    private toMessageAttachments(
        files: IPersistedNoticeBoardPostFile[],
    ): message_CreateMessageAttachment[] {
        return files.map((file) => ({
            name: file.name,
            url: file.url,
            // The api docs are incorrect so we need to type assert this.
            attachment_type:
                file.type as message_CreateMessageAttachment["attachment_type"],
        }))
    }

    private toPersistedFiles(
        messageAttachments: message_MessageAttachment[],
        type: "image" | "doc",
    ) {
        return messageAttachments
            .filter((attachment) => attachment.attachment_type === type)
            .map((attachment) => this.toPesistedFile(attachment, type))
    }

    private toPesistedFile(
        messageAttachment: message_MessageAttachment,
        type: "image" | "doc",
    ): IPersistedFile {
        return {
            name: messageAttachment.name ?? "",
            url: messageAttachment.url ?? "",
            // The document type is called "doc" for this particular api. Rename
            // it to "document", to be consistent with other api:s.
            type: type === "doc" ? "document" : type,
        }
    }

    private async persistAttachments(
        documents: IFile[],
        images: IFile[],
    ): Promise<IPersistedNoticeBoardPostFile[]> {
        const persistedDocuments = await persistFiles(documents, "document")
        const persistedImages = await persistFiles(images, "image")
        return [...persistedDocuments, ...persistedImages].map((file) => ({
            ...file,
            // The document type is called "doc" for this particular api. Map
            // back type for documents from `document` to `doc`.
            type: file.type === "document" ? ("doc" as const) : file.type,
        }))
    }
}
