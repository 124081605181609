import { t } from "@lingui/macro"
import { Typography, useTheme } from "@mui/material"
import { observer } from "mobx-react"

import { SegmentPickerStore } from "src/modals/segment-picker/store"
import { useStore } from "src/store/lib/useStore"

export const SegmentCountFound = observer(() => {
    const theme = useTheme()
    const store = useStore(SegmentPickerStore)

    if (store.availableSegments.meta.count == null) {
        return null
    }

    return (
        <Typography
            fontSize={12}
            fontWeight={400}
            color={theme.palette.grey[700]}
        >
            {t({
                id: "segment-picker.available.segment-count-found",
                values: {
                    count: store.availableSegments.meta.count,
                },
            })}
        </Typography>
    )
})
