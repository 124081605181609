import { useMediaQuery, useTheme } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect } from "react"
import { useLocation } from "react-router"

import { MixpanelProperties } from "src/analytics/constants/properties"
import { getLocationName } from "src/analytics/helpers/getLocationName"
import { trackEvent } from "src/analytics/helpers/mixpanel_tracking"
import { CenteredSpinner } from "src/components/CenteredSpinner"
import { SplitSegmentPicker } from "src/modals/segment-picker/SplitSegmentPicker"
import { TabbedSegmentPicker } from "src/modals/segment-picker/TabbedSegmentPicker"
import { SegmentPickerStore } from "src/modals/segment-picker/store"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"

type ISegmentPickerModalProps = {
    initialSegmentIds: number[]
    accessGroupId: number | null
    allowedSegmentTypes?: ISegmentForSearch["type"][]
    onChange: (segmentIds: number[]) => void
    onClose: () => void
}

const SegmentPickerModalImpl = observer((props: ISegmentPickerModalProps) => {
    const store = useStore(SegmentPickerStore)
    const gstore = useStore(GlobalStore)

    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down("md"))
    const location = useLocation()

    useEffect(() => {
        ;(async () => {
            await store.init(
                props.accessGroupId,
                props.initialSegmentIds,
                props.allowedSegmentTypes,
            )
        })()
    }, [
        store,
        props.accessGroupId,
        props.initialSegmentIds,
        props.allowedSegmentTypes,
    ])

    const onSave = useCallback(() => {
        props.onChange(Array.from(store.selectedSegmentIds))
        trackEvent(
            "General | Save Segment Picker Selection",
            getLocationName(location.pathname),
            {
                [MixpanelProperties.AccessGroupID]:
                    gstore.session.accessGroup?.id,
                [MixpanelProperties.AccessGroupName]:
                    gstore.session.accessGroup?.name,
                [MixpanelProperties.UserID]: gstore.session.user?.adminId,
                [MixpanelProperties.SegmentName]:
                    store.selectedSegments.sourceItems.map((item) => ({
                        name: item.name,
                        type: item.type,
                    })),
                [MixpanelProperties.SegmentID]: Array.from(
                    store.selectedSegmentIds,
                ),
            },
        )
    }, [
        store,
        location,
        props,
        gstore.session.accessGroup,
        gstore.session.user,
    ])

    if (gstore.loading.is(SegmentPickerStore.LoadingKeys.init)) {
        return <CenteredSpinner />
    }

    if (smallScreen) {
        return (
            <TabbedSegmentPicker
                onSave={onSave}
                onClose={props.onClose}
                initialSegmentIds={props.initialSegmentIds}
            />
        )
    } else {
        return (
            <SplitSegmentPicker
                onSave={onSave}
                onClose={props.onClose}
                initialSegmentIds={props.initialSegmentIds}
            />
        )
    }
})

export const SegmentPickerModal = observer(
    (props: ISegmentPickerModalProps) => {
        return (
            <StoreProvider Store={SegmentPickerStore}>
                <SegmentPickerModalImpl {...props} />
            </StoreProvider>
        )
    },
)
