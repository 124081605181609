import { Icon } from "src/components/icons"
import { getSegmentTypeIcon } from "src/lib/segment-type"

interface IProps {
    type: ISegmentForSearch["type"]
    size: number
}

export const SegmentTypeIcon = (props: IProps) => {
    const { icon, color } = getSegmentTypeIcon(props.type)
    return <Icon icon={icon} size={props.size} color={color} />
}
