import { t } from "@lingui/macro"
import { IconButton, InputAdornment, TextField, useTheme } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useMemo } from "react"

import { Cross16 } from "src/components/icons/Cross16"
import { Search24 } from "src/components/icons/Search24"
import { SegmentPickerStore } from "src/modals/segment-picker/store"
import { useStore } from "src/store/lib/useStore"

export const SearchField = observer(() => {
    const theme = useTheme()
    const store = useStore(SegmentPickerStore)

    const handleSearchChange = useCallback(
        async (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => {
            store.setQuery(event.target.value)
            await store.availableSegments.loadInitialPage()
        },
        [store],
    )

    const handleSearchResetClick = useCallback(async () => {
        store.setQuery(null)
        await store.availableSegments.loadInitialPage()
    }, [store])

    const inputProps = useMemo(
        () => ({
            startAdornment: (
                <InputAdornment
                    position="start"
                    sx={{ color: theme.palette.grey[900] }}
                >
                    <Search24 />
                </InputAdornment>
            ),
            endAdornment: (
                <InputAdornment
                    position="end"
                    sx={{ color: theme.palette.grey[900] }}
                >
                    <IconButton
                        title={t`segment-picker.available.clear-search`}
                        onClick={handleSearchResetClick}
                    >
                        <Cross16 />
                    </IconButton>
                </InputAdornment>
            ),
        }),
        [theme, handleSearchResetClick],
    )

    return (
        <TextField
            value={store.query ?? ""}
            onChange={handleSearchChange}
            placeholder={t`segment-picker.available.search-placeholder`}
            InputProps={inputProps}
            size="small"
        />
    )
})
