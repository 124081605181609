import { LocationChipsCommon } from "src/views/content-items/components/LocationChipCommon"

interface ILocationChipsProps {
    locations: string[]
}

export const LocationChips = ({ locations }: ILocationChipsProps) => {
    if (locations.length <= 1) {
        return (
            <>
                {locations.map((location) => (
                    <LocationChipsCommon key={location} title={location} />
                ))}
            </>
        )
    }

    const [firstLocation, ...additionalLocations] = locations
    return (
        <>
            <LocationChipsCommon title={firstLocation} />
            <LocationChipsCommon
                title={
                    <div>
                        {additionalLocations.map((location) => (
                            <div key={location}>{location}</div>
                        ))}
                    </div>
                }
                label={`+${additionalLocations.length}`}
            />
        </>
    )
}
