import { ILocationType } from "src/views/content-items/types"

export const locationSwitch = (location: ILocationType) => {
    const locationPriorityMap: Record<ILocationType, string> = {
        navigation: "priority_main_navigation",
        move_in_checklist: "priority_move_in_checklist",
        overview: "priority_overview",
        spotlight: "priority_spotlight",
        wizard: "priority_wizard",
        all: "all",
    }
    return locationPriorityMap[location]
}
