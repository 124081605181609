import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback } from "react"
import { Stack, Typography } from "@mui/material"

import { FormFields } from "src/modals/navigation-items-detail/components/formFields"
import { FormFieldSet } from "src/components/FormFieldSet"
import AssetLibraryPicker from "src/components/AssetLibraryPicker"
import { useStore } from "src/store/lib/useStore"
import { NavigationItemDetailStore } from "src/modals/navigation-items-detail/store"
import { persistFiles } from "src/lib/file"
import {
    CONTENT_GUIDELINES,
    CONTENT_READ_GUIDELINES,
} from "src/modals/navigation-items-detail/constants"
import { GlobalStore } from "src/store"
import { IUnitOption } from "src/modals/navigation-items-detail/types/integrationUnitOptions"

const unitOptions: IUnitOption[] = [
    { id: "celsius", label: "°C" },
    { id: "cubicMeters", label: "m³" },
]

const IntegrationContentSection = observer(() => {
    const navDetailStore = useStore(NavigationItemDetailStore)
    const globalStore = useStore(GlobalStore)

    const handleImageChange = useCallback(
        async (images: IFile[]) => {
            const image = images[0]
            if (Object.keys(image).length > 0) {
                const uploadedImages = await persistFiles(
                    [image as ILocalFile],
                    "image",
                )
                if (uploadedImages.length > 0) {
                    navDetailStore.form.set("imageUrl", uploadedImages[0].url)
                }
            }
        },
        [navDetailStore.form],
    )

    const handleImageDelete = useCallback(() => {
        navDetailStore.form.set("imageUrl", "")
    }, [navDetailStore.form])

    return (
        <Stack spacing={2}>
            <Typography>
                {t`navigation-items-view.content-section.keep-text-brief`}{" "}
                <FormFields.LinkGuideline
                    link={CONTENT_GUIDELINES}
                    text={t`navigation-items-view.content-section.guidelines`}
                />{" "}
                {t`navigation-items-view.content-section.will-assist-you`}
            </Typography>
            <br />
            <FormFieldSet
                header={t`navigation-items-view.integration-content-section.overview`}
            >
                <FormFields.NavigationItemNumberInput
                    label={t`navigation-items-view.integration-content-section.priority`}
                    key={"priorityOverview"}
                    disabled={navDetailStore.isReadOnly}
                    maxLength={10}
                    helperText={
                        navDetailStore.form.error("priorityOverview") ?? ""
                    }
                    value={navDetailStore.form.get("priorityOverview") ?? 0}
                    handleTextInputChange={(event) => {
                        navDetailStore.form.set(
                            "priorityOverview",
                            Number(event.target.value),
                        )
                    }}
                />
                <br />
                <FormFields.NavigationItemDropdown
                    label={t`navigation-items-view.integration-content-section.units`}
                    optionArray={unitOptions}
                    selectedValue={
                        navDetailStore.form.get("svHeaderOverview") ?? ""
                    }
                    onSelectionChange={(value) => {
                        navDetailStore.form.set(
                            "svHeaderOverview",
                            value.toString(),
                        )
                    }}
                    errorMessage={
                        navDetailStore.form.error("svHeaderOverview") ?? ""
                    }
                    disabled={navDetailStore.isReadOnly}
                />
                <br />
                <br />
                <FormFields.NavigationItemTextInput
                    label={t`navigation-items-view.integration-content-section.subheader-swedish`}
                    key={"svSubHeader"}
                    rowLength={1}
                    disabled={navDetailStore.isReadOnly}
                    maxLength={30}
                    value={navDetailStore.form.get("svSubHeader")}
                    handleTextInputChange={(event) => {
                        navDetailStore.form.set(
                            "svSubHeader",
                            event.target.value,
                        )
                    }}
                />
                <br />
                <FormFields.NavigationItemTextInput
                    label={t`navigation-items-view.integration-content-section.subheader-english`}
                    key={"enSubHeader"}
                    rowLength={1}
                    disabled={navDetailStore.isReadOnly}
                    maxLength={30}
                    value={navDetailStore.form.get("enSubHeader")}
                    handleTextInputChange={(event) => {
                        navDetailStore.form.set(
                            "enSubHeader",
                            event.target.value,
                        )
                    }}
                />
            </FormFieldSet>
            <br />
            <Typography variant="h6">{t`navigation-items-view.integration-content-section.image`}</Typography>
            <FormFieldSet>
                <Typography>
                    {t`navigation-items-view.content-section.upload-image-represent`}{" "}
                    <FormFields.LinkGuideline
                        link={
                            CONTENT_READ_GUIDELINES[
                                globalStore.session.language ?? "sv"
                            ]
                        }
                        text={t`navigation-items-view.content-section.read-guidelines`}
                    />
                </Typography>
                <br />
                <AssetLibraryPicker
                    value={navDetailStore.form.get("imageUrl")}
                    onIconDelete={handleImageDelete}
                    onIconChange={handleImageChange}
                    errorMessage={navDetailStore.form.error("imageUrl") ?? ""}
                    acceptedFormats={["image/png", "image/jpeg", "image/jpg"]}
                    aspectRatio="1/1"
                    disabled={navDetailStore.isReadOnly}
                    activeAssetType={"images"}
                    setErrorMessage={function (
                        _message: string,
                    ): void | undefined {
                        throw new Error("Function not implemented.")
                    }}
                />
            </FormFieldSet>
        </Stack>
    )
})

export default IntegrationContentSection
