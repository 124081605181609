import { observer } from "mobx-react"
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material"

import { content_ContentItemEntity } from "src/api"

export interface IRenderDropdownProps {
    label: string
    disabled?: boolean
    optionArray: { id: number | string; label: string }[]
    selectedValue: string | number | undefined
    onSelectionChange: (value: content_ContentItemEntity | number) => void
    errorMessage?: string | number
}

export const NavigationItemDropdown = observer(
    ({
        optionArray,
        selectedValue,
        onSelectionChange,
        errorMessage,
        disabled,
        label,
    }: IRenderDropdownProps) => {
        const labelId = `${label}-label`
        const dropdownId = `${label}-id`
        return (
            <FormControl style={{ breakInside: "avoid", width: "100%" }}>
                <InputLabel id={labelId}>{label}</InputLabel>
                <Select
                    labelId={labelId}
                    id={dropdownId}
                    value={selectedValue}
                    label={label}
                    onChange={(event) =>
                        onSelectionChange(
                            event.target.value as
                                | content_ContentItemEntity
                                | number,
                        )
                    }
                    error={Boolean(errorMessage)}
                    disabled={disabled}
                >
                    {optionArray?.map(
                        (item: { id: number | string; label: string }) => (
                            <MenuItem
                                value={item.id}
                                key={`${item.id}-${item.label}`}
                            >
                                {item.label}
                            </MenuItem>
                        ),
                    )}
                </Select>
                {Boolean(errorMessage) && (
                    <FormHelperText error={true}>{errorMessage}</FormHelperText>
                )}
            </FormControl>
        )
    },
)
